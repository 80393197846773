import React, { useState } from "react";
import RepertoriHeader from "./Header";
import RepertoriTable from "./Table";
import RepertoriAddModal from "./AddModal";

const Repertori = ({ repertori = [], edit, onChange }) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleAddRepertorio = (repertorio) => {
    onChange([...repertori, repertorio]);
    setShowAddModal(false);
  };

  const handleDeleteRepertorio = (repertorio) => {
    const newRepertori = repertori.filter((item) => item !== repertorio);
    onChange(newRepertori);
    setShowAddModal(false);
  };

  return (
    <>
      <div className="repetori-container">
        <RepertoriHeader
          edit={edit}
          onOpenModal={() => setShowAddModal(true)}
        />
        <RepertoriTable
          repertori={repertori}
          handleDeleteRepertorio={handleDeleteRepertorio}
          edit={edit}
        />
        <RepertoriAddModal
          show={showAddModal}
          handleClose={() => setShowAddModal(false)}
          handleAddRepertorio={handleAddRepertorio}
        />
      </div>
    </>
  );
};

export default Repertori;
