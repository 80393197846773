import httpArcheo from "../helpers/http-archeo";

export const getBibliografia = async (data) => {
  try {
    const response = await httpArcheo.get(
      "/api/private/v3/bibliografia/get",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getBibliografiaById = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/bibliografia/get/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const newBibliografia = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/bibliografia/save",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const deleteBibliografia = async (id) => {
  try {
    const response = await httpArcheo.delete(
      `/api/private/v3/bibliografia/delete/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updateBibliografia = async (data) => {
  try {
    const response = await httpArcheo.put(
      "/api/private/v3/bibliografia/update",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await httpArcheo.post(
      "/api/private/v3/file/upload",
      formData
    );
    return response?.data?.id;
  } catch (error) {
    throw error;
  }
};
