import React, { useState, useCallback, useRef } from "react";
import "./CassetteRecordFileUploadModal.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CrossIcon, MinusIcon } from "../../../icons";
import {
  getHeaders,
  getBatch,
  uploadFile,
  importCassetteRecords,
} from "./apiService";
import { tipoJson } from "./constant";
import ProcessSection from "./ProcessSection/ProcessSection";
import MatchSection from "./MatchSection/MatchSection";
import SpinnerLoading from "../../SpinnerLoading/SpinnerLoading";
import axios from "axios";
import config from "../../../config";
import { saveAs } from "file-saver";
import { useAppContext } from "../../Context/AppContext";

function CassetteRecordFileUploadModal({
  closeModal,
  title = "UPLOAD RECORDS",
  handleFileUploadCallBack,
}) {
  const { comuni, userProfile } = useAppContext();
  const [fileId, setFileId] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [layoutData, setLayoutData] = useState({});
  const [batchData, setBatchData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [origins, setOrigins] = useState([]);
  const [comuneCassettaId, setComuneCassettaId] = useState(null);
  const [group, setGroup] = useState(null);

  const intervalIdRef = useRef(null);

  const handleTipoChange = useCallback(
    (type, selectedOption, originId) => {
      if (type === "origin") {
        const originData = [...origins];

        const selectedOrigin = originData.find(
          (origin) => origin.id === originId
        );
        selectedOrigin.selectedTipo = selectedOption;
        selectedOrigin.tipo = selectedOption?.value;

        const originIndex = originData.findIndex(
          (origin) => origin.id === originId
        );
        originData[originIndex] = selectedOrigin;

        setOrigins(originData);
      }
    },
    [origins]
  );

  const handleAssocChange = useCallback((data) => {
    setLayoutData(data);
  }, []);

  const handleSelectChange = useCallback(
    (type, selectedOption, label, originId) => {
      if (type === "origin") {
        const originData = [...origins];
        const selectedOrigin = originData.find(
          (origin) => origin.id === originId
        );

        if (label === "comune") {
          selectedOrigin.selectedComune = selectedOption;
          selectedOrigin.comune = { index: selectedOption?.index };
        } else if (label === "valore") {
          selectedOrigin.selectedValore = selectedOption;
          selectedOrigin.valore = { index: selectedOption?.index };
        }

        const originIndex = originData.findIndex(
          (origin) => origin.id === originId
        );
        originData[originIndex] = selectedOrigin;

        setOrigins(originData);
      }
    },
    [origins]
  );

  const handleInputChange = useCallback(
    (e, type, label, originId) => {
      if (type === "origin") {
        const originData = [...origins];

        const selectedOrigin = originData.find(
          (origin) => origin.id === originId
        );

        if (label === "comune") {
          selectedOrigin.comune = { value: e.target.value };
        } else if (label === "valore") {
          selectedOrigin.valore = { value: e.target.value };
        }

        const originIndex = originData.findIndex(
          (origin) => origin.id === originId
        );
        originData[originIndex] = selectedOrigin;

        setOrigins(originData);
      }
    },
    [origins]
  );

  const handleKeyChange = useCallback(
    (e, originId) => {
      const originData = [...origins];

      const selectedOrigin = originData.find(
        (origin) => origin.id === originId
      );
      selectedOrigin.chiave = e.target.value;

      const originIndex = originData.findIndex(
        (origin) => origin.id === originId
      );
      originData[originIndex] = selectedOrigin;

      setOrigins(originData);
    },
    [origins]
  );

  const handleComuneCassettaIdChange = useCallback((selectedOption) => {
    setComuneCassettaId(selectedOption);
  }, []);

  const handleGroupChange = useCallback((selectedOption) => {
    setGroup(selectedOption);
  }, []);

  const handleAddOrigin = useCallback(() => {
    setOrigins([
      ...origins,
      {
        comune: null,
        selectedComune: null,
        tipo: "",
        selectedTipo: null,
        chiave: "",
        valore: null,
        selectedValore: null,
        id: new Date().getTime(),
      },
    ]);
  }, [origins]);

  const handleDeleteOrigin = useCallback(
    (index) => {
      const originData = [...origins];
      const newData = originData.filter((origin) => origin.id !== index);
      setOrigins(newData);
    },
    [origins]
  );

  const handleProcess = useCallback(async () => {
    const matchData = {
      fileId,
      layout: {
        ...layoutData,
        groups: { value: group?.name },
        provenienze: origins?.map((origin) => ({
          chiave: origin.chiave,
          comune: origin.comune,
          tipo: origin.tipo,
          valore: origin.valore,
        })),
      },
      comuneCassettaId: comuneCassettaId?.id,
    };
    try {
      const batchId = await importCassetteRecords(matchData);
      setBatchId(batchId);
      setIsProcessing(true);
      intervalIdRef.current = setInterval(async () => {
        const batchResponse = await getBatch(batchId);
        setBatchData(batchResponse?.job);
        if (batchResponse?.job?.status === "finished") {
          clearInterval(intervalIdRef.current);
          setIsProcessing(false);
          // handleFileUploadCallBack();
          // closeModal();
          return;
        }
      }, 5000);

      return () => {
        clearInterval(intervalIdRef.current);
        setIsProcessing(false);
      };
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  }, [fileId, origins, group?.name, comuneCassettaId, layoutData]);

  const handleReportDownload = async () => {
    try {
      const res = await axios.get(
        `${config.archeoBaseUrl}/api/private/v3/file/download/${batchData?.outputFileId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, `${batchData?.name}`);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    }
  };

  const handleCSVXlsUpload = useCallback(async (ev) => {
    const files = ev.target.files;
    if (files.length) {
      setIsFileUploading(true);
      Array.from(files).forEach(async (file) => {
        const reader = new FileReader();
        reader.onload = async function () {
          try {
            const uploadedFileId = await uploadFile(file);
            setFileId(uploadedFileId);

            if (uploadedFileId) {
              const headersData = await getHeaders(uploadedFileId);
              setHeaders(headersData);
            }
          } catch (error) {
            toast.error(`Error: ${error.message}`);
          } finally {
            setIsFileUploading(false);
          }
        };

        if (
          file.name.endsWith(".csv") ||
          file.name.endsWith(".xls") ||
          file.name.endsWith(".xlsx")
        ) {
          reader.readAsBinaryString(file);
        } else {
          console.error("Unsupported file format.");
          setIsFileUploading(false);
        }
      });
    }
  }, []);

  return (
    <>
      <section className="modal-container">
        {/* modal header */}
        <header className="modal-header">
          <div className="modal-header-content">
            <h1 className="modal-title">{title}</h1>
            <div className="modal-icons">
              <div className="modal-icon" onClick={closeModal}>
                <MinusIcon />
              </div>
              <div className="modal-icon" onClick={closeModal}>
                <CrossIcon />
              </div>
            </div>
          </div>
        </header>

        {batchId ? (
          <ProcessSection batchData={batchData} isProcessing={isProcessing} />
        ) : (
          <MatchSection
            headers={headers}
            comuni={comuni}
            groups={userProfile?.groups}
            group={group}
            tipoJson={tipoJson}
            origins={origins}
            cassettaId={comuneCassettaId}
            handleTipoChange={handleTipoChange}
            handleKeyChange={handleKeyChange}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            handleAssocChange={handleAssocChange}
            handleCSVXlsUpload={handleCSVXlsUpload}
            handleAddOrigin={handleAddOrigin}
            handleDeleteOrigin={handleDeleteOrigin}
            handleComuneCassettaIdChange={handleComuneCassettaIdChange}
            handleGroupChange={handleGroupChange}
          />
        )}

        {/* footer */}
        {headers.length ? (
          <footer className="modal-footer">
            {batchId ? (
              <button
                className={`modal-footer-button ${
                  isProcessing ? "disable" : ""
                }`}
                onClick={handleReportDownload}
              >
                Download
              </button>
            ) : (
              <button className="modal-footer-button" onClick={handleProcess}>
                Process
              </button>
            )}
          </footer>
        ) : null}
        <SpinnerLoading isLoading={isFileUploading} />
      </section>
    </>
  );
}

export default CassetteRecordFileUploadModal;
