const isEmpty = (value) => {
  if (value == null) return true;
  if (typeof value === "string" && value.trim() === "") return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === "object" && Object.keys(value).length === 0) return true;
  return false;
};

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const getRandomColor = (str) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

const mergeObjectsByProperty = (arr, prop) => {
  const result = [];
  const map = new Map();

  for (const item of arr) {
    if (!map.has(item[prop])) {
      map.set(item[prop], item);
    } else {
      const existingObj = map.get(item[prop]);
      const newObj = {
        ...existingObj,
        quantity: Number(existingObj.quantity) + Number(item.quantity),
      };
      map.set(item[prop], newObj);
    }
  }

  for (const obj of map.values()) {
    result.push(obj);
  }

  return result;
};

const downloadXLS = (binaryString, fileName) => {
  const blob = new Blob([binaryString], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export {
  isEmpty,
  mergeObjectsByProperty,
  deepEqual,
  downloadXLS,
  debounce,
  getRandomColor,
};
