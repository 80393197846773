import CustomModal from "../../CustomModal";
import { MinusIcon, CrossIcon } from "../../../icons";
import FileUploadSection from "../../FileUploadSection/FileUploadSection";

const UploadPdfModal = ({
  title,
  showModal,
  handleUpload,
  closeModal,
}) => {
  return (
    <CustomModal isVisible={showModal} onClose={closeModal}>
      <section className="modal-container">
        <header className="modal-header">
          <div className="modal-header-content">
            <h1 className="modal-title">{title}</h1>
            <div className="modal-icons">
              <div className="modal-icon" onClick={closeModal}>
                <MinusIcon />
              </div>
              <div className="modal-icon" onClick={closeModal}>
                <CrossIcon />
              </div>
            </div>
          </div>
        </header>
        <FileUploadSection handleUpload={handleUpload} acceptType={".pdf"} />
      </section>
    </CustomModal>
  );
};

export default UploadPdfModal;
