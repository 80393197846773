import { useState, useEffect } from "react";
import { getBibliografia } from "../../api/bibliografia.js";

export const useBibliografia = (
  page,
  pageSize,
  setTotalRecords,
  setTotalPages
) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchBiografia = async () => {
      try {
        const response = await getBibliografia({ page, pageSize });
        console.log("response-->>", response);
        setData(response);
        setTotalPages(response?.totalPges || 0);
        setTotalRecords(response?.totalElements || 0);
      } catch (error) {
        console.log("fetch-biblografia-->>", error);
      }
    };

    fetchBiografia();
  }, [page, pageSize, setTotalPages, setTotalRecords]);

  return data;
};
