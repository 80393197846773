export const RackIcon = ({
  height = "26px",
  width = "26px",
  fillColor = "#fff",
}) => {
  return (
    <svg 
      id="Heat-Map-03--Streamline-Carbon" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="-0.5 -0.5 16 16" 
      height={height} 
      width={width} >
         <desc>{"Heat Map 03 Streamline Icon: https://streamlinehq.com"}</desc><defs />
         <title>{"heat-map--03"}</title>
          <path d="M12.65625 1.40625H2.34375a0.938578125 0.938578125 0 0 0 -0.9375 0.9375v10.3125a0.938578125 0.938578125 0 0 0 0.9375 0.9375h10.3125a0.938578125 0.938578125 0 0 0 0.9375 -0.9375V2.34375a0.938578125 0.938578125 0 0 0 -0.9375 -0.9375Zm-3.75 2.8125h-2.8125V2.34375h2.8125Zm0 0.9375v1.875h-2.8125v-1.875Zm-3.75 0v1.875H2.34375v-1.875Zm0 2.8125v1.875H2.34375v-1.875Zm0.9375 0h2.8125v1.875h-2.8125Zm3.75 -0.9375v-1.875h2.8125l0 1.875ZM2.34375 10.78125h2.8125v1.875H2.34375Zm7.5 1.875v-1.875h2.8125v1.875Z" fill={fillColor} strokeWidth={1} />
           <path id="_Transparent_Rectangle_" d="M0 0h15v15H0Z" fill="none" strokeWidth={1} />
      </svg>

  );
};
