import regex from "../../regex";

const validateArchivio = (data) => {
  let issues = [];
  if (regex.uuid.test(data["id"]) === false)
    issues.push("Il campo 'ID' del tuo archivio storico è mancante o non valido.");
  return issues;
};

export default validateArchivio;
