import React, { useState, useEffect } from "react";

import { DeleteIcon, MapIcon } from "../../../icons";
import { useAppContext } from "../../Context/AppContext";
import CustomTooltip from "../../CustomTooltip";
import "../Repertori.scss";

const headers = [
  { key: "tipo", label: "Tipo di repertorio", width: "40%" },
  { key: "codice", label: "Codice", width: "40%" },
  { key: "action", label: "", width: "20%" },
];

const RepertoriTable = ({ repertori, handleDeleteRepertorio, edit }) => {
  const { mapViewTarget, setMapViewTarget } = useAppContext();

  /* ADD ACTION BUTTON */
  repertori?.forEach((repertorio) => {
    repertorio["action"] = edit ? (
      <CustomTooltip text="Elimina repertorio">
        <button
          className="repertori-icons"
          onClick={() => handleDeleteRepertorio(repertorio)}
        >
          <DeleteIcon />
        </button>
      </CustomTooltip>
    ) : <></>
  });

  return (
    <>
      <div className="repertori-table-container">
        <div className="">
          <header className="repertori-table-header">
            {headers?.map((header, index) => (
              <div
                key={index}
                className="repertori-header-item"
                style={{
                  maxWidth: `${header.width}`,
                  minWidth: `${header.width}`,
                }}
              >
                <div className="repertori-header-item-text">{header.label}</div>
              </div>
            ))}
          </header>
          {repertori && repertori.length > 0 ? (
            repertori.map((item, index) => {
              return (
                <div className={`repertori-row`} key={index}>
                  {headers?.map((header, headerIndex) => (
                    <div
                      key={index + headerIndex}
                      className={`repertori-row-item ${header.className || ""}`}
                      style={{ width: `${header.width}` }}
                    >
                      {item[header.key]}
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <div className="repertori-row">
              <div className="repertori-row-item" style={{ width: "100%" }}>
                Nessun dato presente
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RepertoriTable;
