import httpArcheo from "../../../helpers/http-archeo";
import { toast } from "react-toastify";

export const getHeaders = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/file/get/${id}/xls/headers`
    );
    return response?.data.headers;
  } catch (err) {
    toast.error(`Error: ${err.message}`);
    throw err;
  }
};

export const importRepertiRecords = async (matchData) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/batch/import/reperti",
      matchData
    );
    return response?.data?.id;
  } catch (err) {
    toast.error(`Error: ${err.message}`);
    throw err;
  }
};

export const getBatch = async (id) => {
  try {
    const response = await httpArcheo.get(`/api/private/v3/batch/get/${id}`);
    return response?.data;
  } catch (err) {
    toast.error(`Error: ${err.message}`);
    throw err;
  }
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await httpArcheo.post(
      "/api/private/v3/file/upload",
      formData
    );
    return response?.data?.id;
  } catch (error) {
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};

export const downloadReport = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/file/download/${id}`,
      {
        responseType: "arraybuffer",
      }
    );
    return response;
  } catch (err) {
    toast.error(`Error: ${err.message}`);
    throw err;
  }
};
