import React, { useState, useEffect, useCallback, useRef } from "react";
import "../Cassette.scss";
import "../../LoginPageText/LoginPageText.scss";
import DataTableSubHeader from "../../DataTableSubHeader/DataTableSubHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddIcon,
  CrossIcon,
  EditIcon,
  FloorIcon,
  MapIcon,
  RackIcon,
  RightArrowIcon,
  ExclamationTriangleIcon,
} from "../../../icons";
import { SubHeaderMobileOption } from "../../SubHeaderMobileOption/SubHeaderMobileOption";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormField from "../../FormField/FormField";
import Raccolta from "../Raccolta/Raccolta";
import ClassSummary from "../ClassSummary";
import cassettaStructure from "../../../constants/dataStructure/cassetta.json";
import {
  getCassetta,
  getReperti,
  updateCassetta,
  createRaccolta,
  updateRaccolta,
  deleteRaccolta,
} from "../../../api";
import ClassList from "../../../assets/json/classi-materiali-flat.json";
import validateCassetta from "../../../helpers/formTools/validations/cassetta/validateCassetta";
import validateRaccolte from "../../../helpers/formTools/validations/raccolte/validateRaccolte";
import userHasPermission from "../../../helpers/formTools/userHasPermission";
import { useAppContext } from "../../Context/AppContext";
import GroupsFormField from "../../GroupsFormField";
import CustomTooltip from "../../CustomTooltip";
import CustomModal from "../../CustomModal";

function CassetteRecordDetailsTable() {
  const { cassettaId, setCassetteFilter, setMapViewTarget, setRepertiFilter, userProfile } = useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [raccolteData, setRaccolteData] = useState([]);
  const [classListComponentKey, setClassListComponentKey] = useState(0);
  const [issues, setIssues] = useState([]);
  const [showIssues, setShowIssues] = useState(false);
  const [numberOfReperti, setNumberOfReperti] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  //let cassettaId = localStorage.getItem("cassettaId")

  const handleFormDataChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  // ****************************************************************************************************
  // ALL API CALLS

  const fetchCassetta = async (id) => {
    try {
      let data = await getCassetta(id);
      setFormData({ ...data });
      let raccolte = data.raccolte.map((r) => {
        let r2 = { ...r };
        r2.provenienze = r.provenienze.map((provenienza) => ({
          ...provenienza,
          comuneId: provenienza.comune.id,
        }));
        return r2;
      });
      setRaccolteData(raccolte);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error("Unauthorized. Redirecting you to the login page...");
      } else {
        console.error(`Error fetching "cassetta" record`, error);
      }
    }
  };

  const editCassetta = async (data) => {
    try {
      await updateCassetta(data);
      toast.success("Cassetta aggiornata con successo!");
    } catch (error) {
      if (error.respomse && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error updating a "cassetta" record`);
      } else {
        toast.error(
          error.response && error.response.detail
            ? error.response.detail
            : `Unknown error updating a "cassetta" record`
        );
        console.error(
          `Authorization error updating a "cassetta" record`,
          error
        );
      }
    }
  };

  const editRaccolta = async (data) => {
    try {
      await updateRaccolta(data);
      // toast.success("Data updated successfully!");  // Reducing the number of toasts
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error updating a "raccolta" record`, data);
      } else {
        toast.error(
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : `Unknown error updating a "raccolta" record`
        );
        console.error(`Unknown error updating a "raccolta" record`, error);
      }
    }
  };

  const newRaccolta = async (data) => {
    try {
      await createRaccolta(data);
      // toast.success("Data updated successfully!");  // Reducing the number of toasts
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error saving a new "raccolta"`, data);
      } else {
        toast.error(
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : `Unknown error saving a new "raccolta"`
        );
        console.error(`Unknown error saving a new "raccolta"`, error);
      }
    }
  };

  const removeRaccolta = async (id) => {
    try {
      await deleteRaccolta(id);
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error deleting a "raccolta" record`, id);
      } else {
        toast.error(
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : `Unknown error deleting a "raccolta" record`
        );
        console.error(`Unknown error deleting a "raccolta" record`, error);
      }
    }
  };

  const fetchNumberOfReperti = async (raccoltaId) => {
    try {
      const filter = [
        { key: "page", value: 1 },
        { key: "pageSize", value: 1 },
        { key: "raccolta.id", value: raccoltaId },
      ];
      const data = await getReperti(filter);
      setNumberOfReperti((prev) => ({
        ...prev,
        [raccoltaId]: data.totalElements,
      }));
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error fetching data from "reperti"`);
      } else {
        console.error(`Unknown error fetching data from "reperti"`, error);
      }
    }
  };

  //* ****************************************************************************************************
  // ALL EFFECTS

  // FETCHING THE SELECTED CASSETTA FROM BE
  useEffect(() => {
    if (cassettaId) fetchCassetta(cassettaId);
  }, [cassettaId]);

  useEffect(() => {
    let issues = validateCassetta(formData);
    issues = issues.concat(validateRaccolte(raccolteData));
    setIssues(issues);
  }, [formData, raccolteData]);

  useEffect(() => {
    raccolteData.forEach((raccolta) => {
      const numberOfReperti = fetchNumberOfReperti(raccolta.id);
    });
  }, [raccolteData]);

  // ****************************************************************************************************
  // ALL HANDLERS

  const handleSave = async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);
    let cassetta = {
      ...formData,
      comuneId: formData.comune.id,
      groups: formData.groups.map((group) => group.name), // Convert groups to array of ids
    };
    delete cassetta.raccolte;
    const response = await editCassetta(cassetta);
    await Promise.all(
      raccolteData.map(async (raccolta) => {
        if (formData.raccolte.find((r) => r.id === raccolta.id))
          await editRaccolta({ ...raccolta });
        else {
          raccolta.cassettaId = formData.id;
          await newRaccolta({ ...raccolta });
        }
      })
    );
    await Promise.all(
      formData.raccolte.map(async (raccolta) => {
        if (!raccolteData.find((r) => r.id === raccolta.id))
          await removeRaccolta(raccolta.id);
      })
    );
    setCassetteFilter([]); // Reset the filter
  };

  const jumpToReperti = (raccoltaId) => {
    setRepertiFilter([{ key: "raccolta.id", value: raccoltaId }]);
    navigate("/reperti");
  };


  const handleMapView = (e) => {
    const target = { layer: e.tipo, key: e.chiave, value: e.valore };
    setMapViewTarget(target);
    navigate("/MapView");
  };

  const handleResourceView = (e) => {
    setIsModalVisible(true);
  };

  // ****************************************************************************************************

  const disabled = pathname !== "/cassette/edit";
  const cassetteRecordDetailsBreadcrumbs = [
    {
      id: 1,
      label: "Dati Archeoligici",
      iconUrl: true,
    },
    {
      id: 2,
      label: "CASSETTE",
      iconUrl: true,
    },
    { id: 3, label: "Edit CASSETTE Record Details", iconUrl: "" },
  ];

  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Back",
          },
           userHasPermission({userProfile, resource: "CASSETTA", resourceGroups: formData?.groups, permission: "WRITE"}) && {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/cassette/edit");
            },
            label: "Edita",
          },
        ]
      : [],
    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <CrossIcon />
            <span className="login-text">Cancel</span>
          </button>,
          <button
            onClick={() => {
              handleSave();
            }}
            className="save-button"
          >
            <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };
  return (
    <>
      <div className="container">
        <main className="record-detail-main-content">
          <DataTableSubHeader
            title={"CASSETTE Record Details"}
            breadcrumbsData={cassetteRecordDetailsBreadcrumbs}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <CassetteRecordDetailForm
            classListComponentKey={classListComponentKey}
            data={formData}
            handleMapView={handleMapView}
            issues={issues}
            showIssues={showIssues}
            raccolteData={raccolteData}
            numberOfReperti={numberOfReperti}
            setData={setFormData}
            setRaccolteData={setRaccolteData}
            disabled={disabled}
            onFieldChange={handleFormDataChange}
            setClassListComponentKey={setClassListComponentKey}
            jumpToReperti={jumpToReperti}
            handleResourceView={handleResourceView}
            //handleCallbackListFormData={handleCallbackListFormData}
            //handleCallbackAddToListFormData={handleCallbackAddToListFormData}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            buttons={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
        <CustomModal 
          isVisible={isModalVisible} 
          onClose={() => setIsModalVisible(false)}>
            Risorsa attualmente non disponibile
        </CustomModal>
      </div>
    </>
  );
}

function CassetteRecordDetailForm({
  data,
  handleMapView,
  handleResourceView,
  raccolteData,
  numberOfReperti,
  setData,
  setRaccolteData,
  disabled,
  jumpToReperti,
  issues,
  showIssues,
}) {

  const handleFieldChange = (key, newValue) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleRaccoltaChange = (index, key, newValue) => {
    const newRaccolteData = raccolteData.map((r, i) => {
      if (i === index) return { ...r, [key]: newValue };
      return r;
    });
    setRaccolteData(newRaccolteData);
  };

  const handleDeleteRaccolta = (raccolta) => {
    let newRaccolteData = raccolteData.filter((r) => {
      return raccolta.id !== r.id;
    });
    setRaccolteData(newRaccolteData);
  };

  if (data && Object.keys(data).length > 0) {
    return (
      <>
        <main className="cassette-main-container">
          <div className="content-wrapper">
            <div className="issue-container">
              {!disabled &&
                showIssues &&
                issues.map((issue, index) => {
                  return (
                    <div key={index} className="issue">
                      <p className="issue-icon">
                        <ExclamationTriangleIcon />{" "}
                      </p>
                      {issue}
                    </div>
                  );
                })}
            </div>
            <div className="blocks-container">
              <div className="form-block" id="generalità">
                <p className="section-title"> GENERALITÀ</p>
                <FormField
                  fieldStructure={cassettaStructure["id"]}
                  value={data["id"]}
                  onChange={(newValue) => handleFieldChange("id", newValue)}
                  disabled={true}
                />
                <GroupsFormField
                  groups={data["groups"]}
                  onChange={(newValue) => handleFieldChange("groups", newValue)}
                  edit={!disabled}
                />
                <FormField
                  fieldStructure={cassettaStructure["frontalino"]}
                  value={data["frontalino"]}
                  onChange={(newValue) =>
                    handleFieldChange("frontalino", newValue)
                  }
                  disabled={disabled}
                />
              </div>
              <div className="form-block" id="posizione">
                <p className="section-title"> POSIZIONE</p>
                <div className="posizione-row">
                  <div className="posizione-row-field">
                    <FormField
                      fieldStructure={cassettaStructure["deposito"]}
                      value={data["deposito"]}
                      onChange={(newValue) =>
                        handleFieldChange("deposito", newValue)
                      }
                      disabled={disabled}
                    />
                  </div>
                  { disabled &&  ( 
                     <div className="posizione-row-icon">
                        <CustomTooltip text="Visualizza su mappa">
                            <button
                              className="provenienze-icons"
                              onClick={() => handleMapView({})}
                            >
                              <MapIcon fillColor="#e15615" />
                            </button>
                        </CustomTooltip> 
                      </div>
                    )}
                </div>
                <div className="posizione-row">
                  <div className="posizione-row-field">
                    <FormField
                      fieldStructure={cassettaStructure["piano"]}
                      value={data["piano"]}
                      onChange={(newValue) =>
                        handleFieldChange("piano", newValue)
                      }
                      disabled={disabled}
                    />
                  </div>
                  { disabled &&  ( 
                      <div className="posizione-row-icon">
                        <CustomTooltip text="Visualizza pianta">
                            <button
                              className="provenienze-icons"
                              onClick={() => handleResourceView({})}
                            >
                              <FloorIcon fillColor="#e15615" />
                            </button>
                        </CustomTooltip> 
                      </div>
                    )}
                </div>
                <div className="posizione-row">
                  <div className="posizione-row-field">
                    <FormField
                      fieldStructure={cassettaStructure["scaffale"]}
                      value={data["scaffale"]}
                      onChange={(newValue) =>
                        handleFieldChange("scaffale", newValue)
                      }
                      disabled={disabled}
                    />
                  </div>
                  { disabled &&  ( 
                      <div className="posizione-row-icon">
                        <CustomTooltip text="Visualizza disposizione">
                            <button
                              className="provenienze-icons"
                              onClick={() => handleResourceView({})}
                            >
                              <RackIcon fillColor="#e15615" />
                            </button>
                        </CustomTooltip> 
                      </div>
                    )}
                </div>
                <div className="posizione-row">
                  <div className="posizione-row-field">
                    <FormField
                      fieldStructure={cassettaStructure["ripiano"]}
                      value={data["ripiano"]}
                      onChange={(newValue) =>
                        handleFieldChange("ripiano", newValue)
                      }
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-block"
                id="raccolte"
                style={{ gridRow: "span 3" }}
              >
                <p className="section-title"> RACCOLTE</p>
                {raccolteData.map((raccolta, index) => {
                  return (
                    <Raccolta
                      key={index}
                      raccolta={raccolta}
                      handleFieldChange={(key, newValue) =>
                        handleRaccoltaChange(index, key, newValue)
                      }
                      handleDeleteRaccolta={() =>
                        handleDeleteRaccolta(raccolta)
                      }
                      edit={!disabled}
                      numberOfReperti={numberOfReperti[raccolta.id]}
                      jumpToReperti={() => jumpToReperti(raccolta.id)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="description-column"></div>
          </div>
          {/* <CassetteRecordDetailImages disabled={disabled} /> */}
        </main>
      </>
    );
  }
}

export default CassetteRecordDetailsTable;
