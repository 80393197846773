import React, { useState, useMemo } from "react";
import "./Pubblicazioni.scss";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../Context/AppContext.jsx";
import { toast } from "react-toastify";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader.jsx";
import ItemsTable from "../ItemsTable/ItemsTable.jsx";
import { SubHeaderMobileOption } from "../SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { EyeIcon, DeleteIcon } from "../../icons/index.js";
import { useBibliografia } from "./useBibliografia";
import { subHeaderRightNavOptions } from "./subHeaderOptions";
import datastructure from "../../constants/dataStructure/biblografia.json";
import { deleteBibliografia } from "../../api/bibliografia.js";
import userHasPermission from "../../helpers/formTools/userHasPermission";

// Breadcrumbs data for navigation
const breadcrumbsData = [
  { id: 1, label: "Dati Archeologici", iconUrl: true },
  { id: 2, label: "Pubblicazioni", iconUrl: true },
  { id: 3, label: "Elenco pubblicazioni", iconUrl: "" },
];

const headers = [
  { key: "actions", label: "actions", width: "100px", sortable: false },
  { key: "id", label: "Identificativo", width: "180px", sortable: true },
  { key: "titolo", label: "Titolo", width: "100px", sortable: true },
  { key: "fonte", label: "Fonte", width: "100px", sortable: true },
  {
    key: "annoPubblicazione",
    label: "Anno di pubblicazione",
    width: "300px",
    sortable: true,
  },
  {
    key: "abbreviazione",
    label: "Abbreviazione",
    width: "100px",
    sortable: true,
  },
];

const filterValues = [
  { label: "id", key: "id" },
  { label: "titolo", key: "titolo" },
  { label: "fonte", key: "fonte" },
  { label: "piano", key: "piano" },
  { label: "annoPubblicazione", key: "annoPubblicazione" },
  { label: "abbreviazione", key: "abbreviazione" },
];

function Pubblicazioni() {
  const navigate = useNavigate();
  const { setBibliografiaFilter, setBibliografiaId, userProfile } = useAppContext();

  const [isMenuActive, setMenuActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [sortDirection, setSortDirection] = useState("asc");
  const userCanCreate = userHasPermission({userProfile, resource: "BIBLIOGRAFIA", permission: "CREATE"});

  const data = useBibliografia(
    currentPage,
    recordsPerPage,
    setTotalRecords,
    setTotalPages
  );

  // Toggle menu active state
  const toggleMenuActive = () => setMenuActive(!isMenuActive);

  const removeBibliografia = async (id) => {
    try {
      await deleteBibliografia(id);
      console.log("remove-biblografia-->>", id);
      toast.success("Data deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 20);
    } catch (error) {
      console.log("remove-biblografia-->>", error);
      toast.error("Data deletion failed!");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRecordsPerPageChange = (newRecordsPerPage) => {
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); // Reset to first page when records per page changes
  };

  async function handleCallBackSort(key) {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    setBibliografiaFilter([{ key: "sort", value: `${key},${sortDirection}` }]);
  }

  const convertedData = useMemo(() => {
    if (!Array.isArray(data.results)) return [];

    let mappedData = []; // Initialize the array to store the converted data

    data?.results.forEach((result) => {
      let jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

      // Convert row attributes in the right format base on a key and a value to be displayed
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          let value;
          if (datastructure[key] && datastructure[key].type === "object") {
            value = result[key][datastructure[key].objectName];
          } else {
            value =
              result[key] !== undefined && result[key] !== null
                ? result[key].toString()
                : "";
          }
          jsonObject[key] = {
            value,
          };
        }
      }

      // Identifies the ID of the record
      const Id = jsonObject.id.value.replace(/^"|"$/g, "");

      // Add the actions to the row
      jsonObject.actions = {
        value: [],
        component: (
          <>
            <div
              onClick={() => {
                navigate("/pubblicazioni/view");
                setBibliografiaId(Id);
              }}
              className="row-icon"
              style={{ cursor: "pointer" }}
            >
              <EyeIcon />
            </div>
            { userHasPermission({userProfile, resource: "BIBLIOGRAFIA", resourceGroups: result.groups, permission: "DELETE"}) && (<div
                onClick={() => removeBibliografia(Id)}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </div>) }
          </>
        ),
      };
      mappedData.push(jsonObject);
    });

    return mappedData;
  }, [data, setBibliografiaId, navigate]);

  console.log("convertedData", convertedData);

  return (
    <div className="container">
      <main className="record-main-content">
        <DataTableSubHeader
          title="PUBBLICAZIONI"
          breadcrumbsData={breadcrumbsData}
          subHeaderRightNavOptions={subHeaderRightNavOptions(userCanCreate, navigate)}
          isMenuActive={isMenuActive}
          setMenuActive={toggleMenuActive}
        />
        <ItemsTable
          headers={headers}
          data={convertedData}
          filterValues={filterValues}
          totalRecords={totalRecords}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onRecordsPerPageChange={handleRecordsPerPageChange}
          callBackSort={handleCallBackSort}
        />
      </main>
      {isMenuActive && (
        <SubHeaderMobileOption
          components={subHeaderRightNavOptions(navigate).components}
          icons={subHeaderRightNavOptions(userCanCreate, navigate).icons}
          onCrossClick={() => setMenuActive(false)}
          title="Options"
        />
      )}
    </div>
  );
}

export default Pubblicazioni;
