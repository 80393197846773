import { ExclamationTriangleIcon, CopyIcon } from "../../../icons";
import FormField from "../../FormField/FormField";
import UploadArchivioPdf from "../UploadArchivioPdf/UploadArchivioPdf";

const ArchivioDetailForm = ({
  showIssues,
  issues,
  disabled = false,
  formData,
  archivioStructure,
  copyToClipboard,
  handleFormDataChange,
}) => {
  return (
    <div className="content-wrapper">
      <div className="issue-container">
        {showIssues &&
          issues.map((issue, index) => {
            return (
              <div key={index} className="issue">
                <p className="issue-icon">
                  <ExclamationTriangleIcon />{" "}
                </p>
                {issue}
              </div>
            );
          })}
      </div>
      <div className="input-fields-column pubblicazioni-input-fields">
        <div className="left-column" style={{ width: "100%" }}>
          <div className="form-block">
            <p className="section-title">GENERALITÀ</p>
            <div className="reperto-id-header">
              <p className="reperto-id-text">Identificativo</p>
              <p
                className="copy-text"
                onClick={() => copyToClipboard(formData?.id)}
              >
                {formData?.id}
                <CopyIcon />
              </p>
            </div>
            <FormField
              fieldStructure={archivioStructure["comune"]}
              value={formData["comune"]}
              onChange={(newValue) => handleFormDataChange("comune", newValue)}
              disabled={disabled}
            />
            <FormField
              fieldStructure={archivioStructure["sito"]}
              value={formData["sito"]}
              onChange={(newValue) => handleFormDataChange("sito", newValue)}
              disabled={disabled}
            />
            <FormField
              fieldStructure={archivioStructure["fascicolo"]}
              value={formData["fascicolo"]}
              onChange={(newValue) =>
                handleFormDataChange("fascicolo", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={archivioStructure["posizione"]}
              value={formData["posizione"]}
              onChange={(newValue) =>
                handleFormDataChange("posizione", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={archivioStructure["protocollo"]}
              value={formData["protocollo"]}
              onChange={(newValue) =>
                handleFormDataChange("protocollo", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={archivioStructure["anno"]}
              value={formData["anno"]}
              onChange={(newValue) => handleFormDataChange("anno", newValue)}
              disabled={disabled}
            />
          </div>

          {/* classe */}
          <div className="form-block">
            <div className="form-block-header">
              <p className="form-block-header-title">Classe</p>
            </div>
            <FormField
              fieldStructure={archivioStructure["classe"]}
              value={formData["classe"]}
              onChange={(newValue) => handleFormDataChange("classe", newValue)}
              disabled={disabled}
            />
            <FormField
              fieldStructure={archivioStructure["descClasse"]}
              value={formData["descClasse"]}
              onChange={(newValue) =>
                handleFormDataChange("descClasse", newValue)
              }
              disabled={disabled}
            />
          </div>
        </div>
        <div className="right-column" style={{ width: "100%" }}>
          {/* categoria */}
          <div className="form-block">
            <div className="form-block-header">
              <p className="form-block-header-title">Categoria</p>
            </div>
            <FormField
              fieldStructure={archivioStructure["categoria"]}
              value={formData["categoria"]}
              onChange={(newValue) =>
                handleFormDataChange("categoria", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={archivioStructure["descCategoria"]}
              value={formData["descCategoria"]}
              onChange={(newValue) =>
                handleFormDataChange("descCategoria", newValue)
              }
              disabled={disabled}
            />
          </div>
          {/* upload pdf */}
          <UploadArchivioPdf
            idPdfArchivio={formData?.allegati}
            disabled={disabled}
            onChange={(newValue) => {
              handleFormDataChange("allegati", newValue)
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArchivioDetailForm;
