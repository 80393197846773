import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { useAppContext } from "../../Context/AppContext";
import { newBibliografia } from "../../../api";
import DataTableSubHeader from "../../DataTableSubHeader/DataTableSubHeader";
import { AddIcon, CrossIcon } from "../../../icons";
import bibliografiaStructure from "../../../constants/dataStructure/biblografia.json";
import validatePubblicazioni from "../../../helpers/formTools/validations/pubblicazioni/validatePubblicazioni";
import PubblicazioniDetailForm from "../PubblicazioniDetailForm/PubblicazioniDetailForm";
import { useCheckRole } from "../../../hooks/useCheckRole";

const pubblicazioneRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "PUBBLICAZIONI",
    iconUrl: true,
  },
  { id: 3, label: "Add PUBBLICAZIONI Record Details", iconUrl: "" },
];

const AddPubblicazioni = () => {
  const navigate = useNavigate();
  const { setBibliografiaFilter } = useAppContext();

  const [isMenuActive, setMenuActive] = useState(false);
  const [formData, setFormData] = useState({});
  const [showIissues, setShowIssues] = useState(false);

  useCheckRole("BIBLIOGRAFIA_WRITE");

  const issues = useMemo(() => validatePubblicazioni(formData), [formData]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, id: uuidv4() }));
  }, []);

  const newPubblicazioni = async (data) => {
    try {
      await newBibliografia(data);
      toast.success("Data updated successfully!");
    } catch (error) {
      toast.error(
        error.response && error.response.detail
          ? error.response.detail
          : `Unknown error saving a new "Publicazione"`
      );
    }
  };

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  const handleSave = useCallback(async () => {
    console.log("formData", formData);
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);

    try {
      const submitData = {
        ...formData,
        allegati: formData?.allegati?.map((item) => item.id),
      };
      await newPubblicazioni(submitData);
      setBibliografiaFilter([]);
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    }
  }, [formData, issues, setBibliografiaFilter, navigate]);

  const subHeaderRightNavOptions = {
    icons: [],
    components: [
      <button onClick={() => navigate(-1)} className="cancel-button">
        <CrossIcon />
        <span className="login-text">Cancel</span>
      </button>,
      <button
        onClick={() => {
          handleSave();
        }}
        className="save-button"
      >
        <AddIcon fillColor="#fff" />
        <span className="login-text">Save</span>
      </button>,
    ],
  };

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"PUBBLICAZIONI Record Details"}
          breadcrumbsData={pubblicazioneRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <PubblicazioniDetailForm
          showIssues={showIissues}
          issues={issues}
          formData={formData}
          bibliografiaStructure={bibliografiaStructure}
          copyToClipboard={copyToClipboard}
          handleFormDataChange={handleFormDataChange}
          create={true}
        />
      </main>
    </div>
  );
};

export default AddPubblicazioni;
