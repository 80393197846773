import React, { useEffect, useMemo, useState, useCallback } from "react";
import "./Archivio.scss";
import { useNavigate } from "react-router-dom";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader.jsx";
import { EyeIcon, DeleteIcon } from "../../icons/index.js";
import { useAppContext } from "../Context/AppContext.jsx";
import { getArchivio, deleteArchivio } from "../../api/archivio.js";
import { SubHeaderMobileOption } from "../SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { subHeaderRightNavOptions } from "./SubHeaderNavOption/SubHeaderNavOption.jsx";
import datastructure from "../../constants/dataStructure/archivio.json";
import ItemsTable from "../ItemsTable/ItemsTable.jsx";
import { toast } from "react-toastify";
import userHasPermission from "../../helpers/formTools/userHasPermission";

const breadcrumbsData = [
  { id: 1, label: "Dati Archeologici", iconUrl: true },
  { id: 2, label: "Archivio storico", iconUtl: true },
  { id: 3, label: "Elenco fascicoli", iconUrl: "" },
];

const headers = [
  { key: "actions", label: "actions", width: "100px", sortable: false },
  { key: "id", label: "Identificativo", width: "180px", sortable: true },
  { key: "fascicolo", label: "Fascicolo", width: "100px", sortable: true },
  { key: "posizione", label: "Posizione", width: "100px", sortable: true },
  { key: "protocollo", label: "Protocollo", width: "100px", sortable: true },
  { key: "sito", label: "Sito", width: "100px", sortable: true },
  { key: "comune", label: "Comune", width: "100px", sortable: true },
  { key: "categoria", label: "Categoria", width: "100px", sortable: true },
  { key: "classe", label: "Classe", width: "100px", sortable: true },
];

const filterValues = [
  { label: "id", key: "id" },
  { label: "fascicolo", key: "fascicolo" },
  { label: "posizione", key: "posizione" },
  { label: "protocollo", key: "protocollo" },
  { label: "sito", key: "sito" },
  { label: "comune", key: "comune" },
  { label: "categoria", key: "categoria" },
  { label: "classe", key: "classe" },
];

function Archivio() {
  const { setArchivioFilter, setArchivioId, userProfile } = useAppContext();
  const navigate = useNavigate();
  const [isMenuActive, setMenuActive] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [sortDirection, setSortDirection] = useState("asc");
  const userCanCreate = userHasPermission({userProfile, resource: "ARCHIVIOSTORICO", permission: "CREATE"});



  const toggleMenuActive = useCallback(
    () => setMenuActive(!isMenuActive),
    [isMenuActive]
  );

  const getArchivioData = useCallback(async () => {
    try {
      const response = await getArchivio();
      console.log("--->>>>", response);

      setData(response);
    } catch (err) {
      toast.error("Error fetching data");
    }
  }, []);

  const removeArchivio = useCallback(async (id) => {
    try {
      await deleteArchivio(id);
      console.log("remove-archivio-->>", id);
      toast.success("Data deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 20);
    } catch (error) {
      toast.error("Error deleting data");
    }
  }, []);

  const handlePageChange = useCallback(
    (newPage) => {
      setCurrentPage(newPage);
    },
    [setCurrentPage]
  );

  const handleRecordsPerPageChange = useCallback(
    (newRecordsPerPage) => {
      setRecordsPerPage(newRecordsPerPage);
      setCurrentPage(1); // Reset to first page when records per page changes
    },
    [setRecordsPerPage, setCurrentPage]
  );

  const handleCallBackSort = useCallback(
    async (key) => {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
      setArchivioFilter([{ key: "sort", value: `${key},${sortDirection}` }]);
    },
    [setSortDirection, setArchivioFilter, sortDirection]
  );

  useEffect(() => {
    getArchivioData();
  }, [getArchivioData]);

  const convertedData = useMemo(() => {
    if (!Array.isArray(data)) return [];

    let mappedData = []; // Initialize the array to store the converted data

    data.forEach((item) => {
      const result = item?.archivioStoricoEntityBean;

      let jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

      // Convert row attributes in the right format base on a key and a value to be displayed
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          let value;
          if (datastructure[key] && datastructure[key].type === "object") {
            value = result[key][datastructure[key].objectName];
          } else {
            value =
              result[key] !== undefined && result[key] !== null
                ? result[key].toString()
                : "";
          }
          jsonObject[key] = {
            value,
          };
        }
      }

      // Identifies the ID of the record
      const Id = jsonObject.id.value.replace(/^"|"$/g, "");

      // Add the actions to the row
      jsonObject.actions = {
        value: [],
        component: (
          <>
            <div
              onClick={() => {
                navigate("/archivio/view");
                setArchivioId(Id);
              }}
              className="row-icon"
              style={{ cursor: "pointer" }}
            >
              <EyeIcon />
            </div>
            { userHasPermission({userProfile, resource: "ARCHIVIOSTORICO", resourceGroups: result.groups, permission: "DELETE"}) && (<div
              onClick={() => removeArchivio(Id)}
              style={{ cursor: "pointer" }}
            >
              <DeleteIcon />
            </div>)}
          </>
        ),
      };
      mappedData.push(jsonObject);
    });

    return mappedData;
  }, [data, setArchivioId, removeArchivio, navigate]);

  console.log("convertedData", convertedData);
  return (
    <>
      <div className="container">
        <main className="record-main-content">
          <DataTableSubHeader
            title={"ARCHIVIO STORICO"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions(userCanCreate,navigate)}
            isMenuActive={isMenuActive}
            setMenuActive={toggleMenuActive}
          />
          <ItemsTable
            headers={headers}
            data={convertedData}
            filterValues={filterValues}
            totalRecords={totalRecords}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
            callBackSort={handleCallBackSort}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={subHeaderRightNavOptions(userCanCreate,navigate)?.components}
            icons={subHeaderRightNavOptions(userCanCreate,navigate)?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default Archivio;
