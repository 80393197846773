import { useMemo } from "react";
import { EditIcon, TrashIcon } from "../icons";
import isColorDark from "../helpers/utils/isColorDark";
import { getRandomColor } from "../helpers/utils/utils";

const useTransformNewData = (
  users,
  isEditing,
  setIsEditModalOpen,
  setIsDeleteModalOpen,
  setSelectedUser
) => {
  const transformedData = useMemo(() => {
    return users.map((user, index) => ({
      id: { value: (index + 1).toString().padStart(3, "0"), component: "" },
      userName: { value: user.username, component: "" },

      roles: {
        value:
          user?.roles
            ?.map((role) => `${role.name} (${role.authorities?.join(", ")})`)
            .join(", ") || "No value",
        component: (
          <div className="group-wrapper">
            {user?.roles?.length ? (
              user.roles.map((role) => (
                <p
                  key={role.name}
                  className="badge"
                  style={{
                    backgroundColor: getRandomColor(role.name),
                    color: isColorDark(getRandomColor(role.name))
                      ? "#fff"
                      : "#000",
                  }}
                >
                  {role.name || "No value"}
                </p>
              ))
            ) : (
              <p>{user?.roles?.[0]?.name || "No value"}</p>
            )}
          </div>
        ),
      },

      groups: {
        value:
          user?.groups?.map((group) => group.name).join(", ") || "No value",
        component: (
          <div className="group-wrapper">
            {user?.groups?.length ? (
              user.groups.map((group) => (
                <p
                  key={group.name}
                  className={`${group.name ? "badge" : ""}`}
                  style={{
                    backgroundColor: group.color ? group.color : "#ccc",
                    color: isColorDark(group.color) ? "#fff" : "#000",
                  }}
                >
                  {group.name || "No value"}
                </p>
              ))
            ) : (
              <p
                className={`${user?.groups?.[0]?.name ? "badge" : ""}`}
                style={{ backgroundColor: user?.groups?.[0]?.color }}
              >
                {user?.groups?.[0]?.name || "No value"}
              </p>
            )}
          </div>
        ),
      },

      actions: {
        value: [],
        component: isEditing ? (
          <div className="actions-wrapper">
            <div
              className="action-icon"
              onClick={() => {
                setSelectedUser(user);
                setIsEditModalOpen(true);
              }}
            >
              <EditIcon fillColor="#EE4B2B" />
            </div>
            <div
              className="action-icon"
              onClick={() => {
                setSelectedUser(user);
                setIsDeleteModalOpen(true);
              }}
            >
              <TrashIcon width={24} height={24} />
            </div>
          </div>
        ) : null,
      },
    }));
  }, [
    users,
    isEditing,
    setIsEditModalOpen,
    setIsDeleteModalOpen,
    setSelectedUser,
  ]);

  return transformedData;
};

export default useTransformNewData;
