import { useState } from "react";
import { ExclamationTriangleIcon, CopyIcon } from "../../../../icons";
import ImageGallery from "../../../ImageGallery/ImageGallery";
import GroupsFormField from "../../../GroupsFormField";
import FormField from "../../../FormField/FormField";
import Repertori from "../../../Repertori";
import repertoStructure from "../../../../constants/dataStructure/reperto.json";
import Provenienze from "../../../Provenienze";
import ReactModal from "../../../ReactModal/ReactModal";
import IccdEditor from "../../IccdEditor";
import iccdManifest from "../../../../assets/json/ICCD-4.00-manifest-v0.2.json";
import BibliografiaSection from "../../BibliografiaSection/BibliografiaSection";

export default function RepertiRecordDetailForm({
  data,
  setData,
  onRaccoltaChange,
  disabled,
  send,
  issues,
  showIssues,
}) {
  const [iccdOpen, setIccdOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  console.log("reperti-edit--->>>", data);

  const handleFieldChange = (key, newValue) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        // setCopySuccess('Copied!');
        // setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
      },
      (err) => {
        setCopySuccess("Failed to copy!");
      }
    );
  };

  if (data && Object.keys(data).length > 0) {
    return (
      <>
        <main className="reperti-main-container">
          <div className="content-wrapper">
            <div className="issue-container">
              {!disabled &&
                showIssues &&
                issues.map((issue, index) => {
                  return (
                    <div key={index} className="issue">
                      <p className="issue-icon">
                        <ExclamationTriangleIcon />
                      </p>
                      {issue}
                    </div>
                  );
                })}
            </div>
            <div className="blocks-container">
              <div className="form-block" id="generalità">
                <p className="section-title"> GENERALITÀ</p>
                <div className="reperto-id-header">
                  <p className="reperto-id-text"> Codice Identificativo</p>
                  <p
                    className="copy-text"
                    onClick={() => copyToClipboard(data["id"])}
                  >
                    {data["id"]}
                    <CopyIcon />
                  </p>
                </div>
                <GroupsFormField
                  groups={data["groups"]}
                  onChange={(newValue) => handleFieldChange("groups", newValue)}
                  edit={!disabled}
                />
                <Repertori
                  repertori={data["codiciInventario"]}
                  onChange={(newValue) =>
                    handleFieldChange("codiciInventario", newValue)
                  }
                  edit={!disabled}
                />
              </div>
              <div className="form-block" id="galleria">
                <p className="section-title"> GALLERIA IMMAGINI</p>
                <ImageGallery
                  disabled={disabled}
                  send={send}
                  //imageLink={imageLink}
                  immagini={data.immagini}
                  repertoId={data.id}
                />
              </div>
              <div className="form-block" id="bibliografia">
                <BibliografiaSection
                  edit={!disabled}
                  repertiId={data.id}
                  repertiNote={data.note}
                />
              </div>
              <div className="form-block" id="scheda">
                <p className="section-title"> SCHEDA ICCD</p>
                <div className="iccd-button-container">
                  <button
                    className="class-iccd-btn btn"
                    onClick={() => setIccdOpen(true)}
                  >
                    APRI SCHEDA
                  </button>
                </div>
              </div>
              <div className="form-block" id="provenienza">
                <p className="section-title"> PROVENIENZA</p>
                <FormField
                  fieldStructure={repertoStructure["anno"]}
                  value={data["anno"]}
                  // value={data["anno"] ? data["anno"].value : ""}
                  onChange={(newValue) => handleFieldChange("anno", newValue)}
                  disabled={disabled}
                />
                <Provenienze
                  provenienze={data["provenienze"]}
                  onChange={(newValue) =>
                    handleFieldChange("provenienze", newValue)
                  }
                  edit={!disabled}
                />
              </div>
              <div className="form-block" id="caratteristiche">
                <div classname="blockHeader">
                  <p className="section-title"> CARATTERISTICHE</p>
                </div>
                <FormField
                  fieldStructure={repertoStructure["materia"]}
                  //value={data["materiale"] ? data["materiale"].value : ""}
                  value={data["materia"]}
                  onChange={(newValue) =>
                    handleFieldChange("materia", newValue)
                  }
                  disabled={disabled}
                />
                <FormField
                  fieldStructure={repertoStructure["classe"]}
                  value={data["classe"]}
                  onChange={(newValue) => handleFieldChange("classe", newValue)}
                  disabled={true}
                />
                <FormField
                  fieldStructure={repertoStructure["forma"]}
                  //value={data["forma"] ? data["forma"].value : ""}
                  value={data["forma"]}
                  onChange={(newValue) => handleFieldChange("forma", newValue)}
                  disabled={disabled}
                />
                <FormField
                  fieldStructure={repertoStructure["tipo"]}
                  //value={data["tipo"] ? data["tipo"].value : ""}
                  value={data["tipo"]}
                  onChange={(newValue) => handleFieldChange("tipo", newValue)}
                  disabled={disabled}
                />
                <FormField
                  fieldStructure={repertoStructure["cronologia"]}
                  //value={data["cronologia"] ? data["cronologia"].value : ""}
                  value={data["cronologia"]}
                  onChange={(newValue) =>
                    handleFieldChange("cronologia", newValue)
                  }
                  disabled={disabled}
                />
              </div>
              <div className="form-block" id="collocazione">
                <div classname="blockHeader">
                  <p className="section-title">COLLOCAZIONE</p>
                </div>
                <FormField
                  fieldStructure={{
                    label: "Tipo di Collocazione",
                    type: "string",
                    editable: false,
                  }}
                  value={
                    data["raccolta"] && data["raccolta"].cassettaId
                      ? "Deposito"
                      : "-"
                  }
                  onChange={() => {}}
                  disabled={true}
                />
                <FormField
                  fieldStructure={{
                    label: "Identificativo Cassetta",
                    type: "string",
                    editable: true,
                  }}
                  value={data["raccolta"] ? data["raccolta"].cassettaId : "-"}
                  onChange={() => {}}
                  disabled={true}
                />
                <FormField
                  fieldStructure={{
                    label: "Identificatovo Raccolta",
                    type: "string",
                    editable: true,
                  }}
                  value={data["raccolta"] ? data["raccolta"].raccoltaId : "-"}
                  onChange={(newValue) => onRaccoltaChange(newValue)}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          {/* <div className="description-column">
                <Description text={descriptionText} disabled={disabled} />
              </div> */}
          <ReactModal isOpen={iccdOpen} onClose={() => setIccdOpen(false)}>
            <IccdEditor
              manifest={iccdManifest}
              data={data["iccd"]}
              edit={true}
              visibility={1}
              knownData={{}}
              onSave={(value) => handleFieldChange("iccd", value)}
              onClose={() => setIccdOpen(false)}
            />
          </ReactModal>
        </main>
      </>
    );
  }
}
