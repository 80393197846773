import DataTable from "react-data-table-component";
import { DeleteIcon, ArchiveIcon } from "../../../../icons";
import "./BibliografiaTable.scss";

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "#f4f4f4",
    },
  },
};

const BibliografiaTable = ({
  edit,
  bibliografiaData,
  handleDeleteBibliografia,
  handleBibliografiaView,
}) => {
  const columns = [
    {
      name: "Titolo",
      selector: (row) => row.titolo,
      sortable: true,
    },
    {
      name: "Abbreviazione",
      selector: (row) => row.abbreviazione,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => row.id,
      cell: (row) => (
        <div>
          {edit ? (
            <div>
              <button
                className="action-btn"
                onClick={() => handleDeleteBibliografia(row.id)}
              >
                <DeleteIcon />
              </button>
            </div>
          ) : (
            <button
              className="action-btn"
              onClick={() => handleBibliografiaView(row.id)}
            >
              <ArchiveIcon fillColor="#e15615" />
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={bibliografiaData}
        customStyles={customStyles}
      />
    </div>
  );
};

export default BibliografiaTable;
