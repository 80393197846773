export const CrossIcon = ({ width = 28, height = 28 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0002 2.33337C7.57183 2.33337 2.3335 7.57171 2.3335 14C2.3335 20.4284 7.57183 25.6667 14.0002 25.6667C20.4285 25.6667 25.6668 20.4284 25.6668 14C25.6668 7.57171 20.4285 2.33337 14.0002 2.33337ZM17.9202 16.6834C18.2585 17.0217 18.2585 17.5817 17.9202 17.92C17.7452 18.095 17.5235 18.1767 17.3018 18.1767C17.0802 18.1767 16.8585 18.095 16.6835 17.92L14.0002 15.2367L11.3168 17.92C11.1418 18.095 10.9202 18.1767 10.6985 18.1767C10.4768 18.1767 10.2552 18.095 10.0802 17.92C9.74183 17.5817 9.74183 17.0217 10.0802 16.6834L12.7635 14L10.0802 11.3167C9.74183 10.9784 9.74183 10.4184 10.0802 10.08C10.4185 9.74171 10.9785 9.74171 11.3168 10.08L14.0002 12.7634L16.6835 10.08C17.0218 9.74171 17.5818 9.74171 17.9202 10.08C18.2585 10.4184 18.2585 10.9784 17.9202 11.3167L15.2368 14L17.9202 16.6834Z"
      fill="#EE4B2B"
    />
  </svg>
);
