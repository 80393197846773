import CustomModal from "../../Common/CustomModal";
import "./DeleteUserModal.scss";

const DeleteUserModal = ({ show, handleClose, handleDelete, user }) => {
  return (
    <CustomModal title="Delete User" isVisible={show} onClose={handleClose}>
      <div className="delete-user-modal-body">
        <div className="delete-user-modal-content">
          <div className="delete-user-modal-title">
            Are you sure you want to delete this user?
          </div>
          <div className="delete-user-modal-username">{user}</div>
        </div>
        <footer className="modal-footer">
          <button
            type="button"
            className="modal-footer-button"
            onClick={() => handleDelete(user)}
          >
            Delete
          </button>
        </footer>
      </div>
    </CustomModal>
  );
};

export default DeleteUserModal;
