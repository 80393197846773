import { useState, useCallback, useEffect } from "react";
import Select from "react-select";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import CustomModal from "../../Common/CustomModal";
import { setUserGroups, setUserRoles, updateAdminPwd } from "../../../api";
import { EditIcon, CrossIcon } from "../../../icons";
import "./EditUserModal.scss";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

const EditUserModal = ({
  show,
  user,
  rolesData,
  groupsData,
  handleClose,
  handleEdit,
}) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { username } = user;

      try {
        if (isUpdatePassword && newPassword) {
          await updateAdminPwd({ username, newPassword });
        }
        await setUserRoles({
          username,
          roles: selectedRoles?.map((role) => role.id),
        });
        await setUserGroups({
          username,
          groups: selectedGroups?.map((group) => group.value),
        });

        toast.success("User updated successfully");
        // handleEdit();
      } catch (err) {
        console.error("Error while updating user", err);
        toast.error("Error while updating user");
      }
    },
    [isUpdatePassword, newPassword, user, selectedRoles, selectedGroups]
  );

  const handleChange = useCallback((e) => {
    setNewPassword(e.target.value);
  }, []);

  const handleRoleSelectChange = useCallback((selectedRole) => {
    setSelectedRoles(selectedRole);
  }, []);

  const handleGroupSelectChange = useCallback((selectedGroup) => {
    setSelectedGroups(selectedGroup);
  }, []);

  useEffect(() => {
    setSelectedRoles(
      user.roles.map((role) => ({
        id: role?.id,
        value: role?.authorities,
        label: role?.name,
      }))
    );
    setSelectedGroups(
      user.groups?.map((group) => ({
        value: group.name,
        color: group.color,
        label: group.name,
      }))
    );
  }, [user]);

  return (
    <CustomModal isVisible={show} onClose={handleClose} title="Edit User">
      <form onSubmit={handleSubmit}>
        <div className="edit-user-modal-body">
          <div className="input-wrapper">
            <label htmlFor="username" className="input-label">
              Username:
            </label>
            <p className="username-text">{user?.username}</p>
          </div>

          <div className="input-wrapper">
            <label htmlFor="password" className="input-label">
              Password:
            </label>
            <input
              id="password"
              type="password"
              name="password"
              disabled={isUpdatePassword ? false : true}
              value={newPassword}
              onChange={handleChange}
            />
            <button
              className="pwd-btn"
              onClick={() => {
                setIsUpdatePassword(!isUpdatePassword);
              }}
            >
              {isUpdatePassword ? (
                <CrossIcon />
              ) : (
                <EditIcon fillColor="#EE4B2B" />
              )}
            </button>
          </div>

          <div className="input-wrapper">
            <label htmlFor="roles" className="input-label">
              Roles:
            </label>
            <Select
              isMulti
              name="roles"
              options={rolesData}
              value={selectedRoles}
              placeholder="Select a role."
              onChange={handleRoleSelectChange}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="groups" className="input-label">
              Groups:
            </label>
            <Select
              isMulti
              name="groups"
              options={groupsData}
              value={selectedGroups}
              placeholder="Select a group."
              styles={colourStyles}
              onChange={handleGroupSelectChange}
            />
          </div>
        </div>
        <footer className="modal-footer">
          <button type="submit" className="modal-footer-button">
            Update
          </button>
        </footer>
      </form>
    </CustomModal>
  );
};

export default EditUserModal;
