import { useState } from "react";
import CustomTooltip from "../../CustomTooltip";
import { AddIcon, DeleteIcon } from "../../../icons";
import AddAutoriModal from "../AddAutoriModal/AddAutoriModal";
import "./AutoriTable.scss";

const headers = [
  { key: "nome", label: "Nome", width: "30%" },
  { key: "cognome", label: "Cognome", width: "30%" },
  { key: "action", label: "", width: "10%" },
];

const AutoriTableHeader = () => (
  <header className="autori-table-header">
    {headers.map((header, index) => (
      <div
        key={index}
        className="autori-header-item"
        style={{
          maxWidth: header.width,
          minWidth: header.width,
        }}
      >
        <p className="autori-header-item-text">{header.label}</p>
      </div>
    ))}
  </header>
);

const AutoriTableRow = ({ item, edit, onDelete }) => (
  <div className="autori-row">
    {headers.map((header, headerIndex) => (
      <div
        key={`${item.nome}-${headerIndex}`}
        className={`autori-row-item ${header.className || ""}`}
        style={{ width: header.width }}
      >
        {item[header.key]}
      </div>
    ))}
    {edit && (
      <div className="autori-table-actions">
        <CustomTooltip text="Elimina autore">
          <button className="autori-icons" onClick={() => onDelete(item)}>
            <DeleteIcon width="" />
          </button>
        </CustomTooltip>
      </div>
    )}
  </div>
);

const AutoriTable = ({ autori = [], edit, onChange }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleAddAutore = (newAutore) => {
    onChange([...autori, newAutore]);
  };

  const handleDeleteAutore = (autoreToDelete) => {
    const updatedAutori = autori.filter((autore) => autore !== autoreToDelete);
    onChange(updatedAutori);
  };

  return (
    <div className="autori-table-container">
      <div className="autori-header">
        <p className="autori-header-title">Autori</p>
        {edit && (
          <CustomTooltip text="Aggiungi autore">
            <button className="autori-button" onClick={handleOpenModal}>
              <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        )}
      </div>
      <AutoriTableHeader />
      {autori.length > 0 ? (
        autori.map((item) => (
          <AutoriTableRow
            key={item.nome}
            item={item}
            edit={edit}
            onDelete={handleDeleteAutore}
          />
        ))
      ) : (
        <div className="autori-row">
          <div className="autori-row-item" style={{ width: "100%" }}>
            Nessun dato presente
          </div>
        </div>
      )}
      <AddAutoriModal
        show={showModal}
        handleClose={handleCloseModal}
        handleAdd={handleAddAutore}
      />
    </div>
  );
};

export default AutoriTable;
