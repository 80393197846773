import React from "react";
import "../Repertori.scss";
import { AddIcon } from "../../../icons";
import CustomTooltip from "../../CustomTooltip";

const RepertoriHeader = ({ edit = false, onOpenModal }) => {
  return (
    <div className="repertori-header">
      <p className="repertori-header-title">Codici di inventario</p>
      {edit && (
        <CustomTooltip text="Aggiungi repertorio">
          <button className="repertori-button" onClick={onOpenModal}>
            <AddIcon fillColor="#e15615" />
          </button>
        </CustomTooltip>
      )}
    </div>
  );
};

export default RepertoriHeader;
