import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getMe } from "../api";

export const useCheckRole = (role) => {
  const navigate = useNavigate();

  useEffect(() => {
    getMe()
      .then((res) => {
        if (
          !res?.roles ||
          !res?.roles.some((r) =>
            r.authorities.some((aut) => aut === "ADMIN" || aut === role)
          )
        ) {
          toast.error("Le credenziali non consentono di accedere a questa pagina");
          navigate("/signin");
        }
      })
      .catch((err) => {
        console.error("Error while fetching user data", err);
      });
  }, [role, navigate]);
};
