import httpArcheo from "../helpers/http-archeo";

export const getRoles = async () => {
  try {
    const response = await httpArcheo.get("/api/private/v3/role/get");
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const saveRole = async (data) => {
  try {
    const response = await httpArcheo.post("/api/private/v3/role/save", data);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const setRoles = async (data) => {
  try {
    const response = await httpArcheo.post("/api/private/v3/roles/set", data);
    return response?.data;
  } catch (err) {
    throw err;
  }
};
