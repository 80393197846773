// CHECK IF THE USER HAS THE REQUIRED PERMISSION
// - The userProfile must be an object with roles and groups arrays
// - The resource must be a string (i.e. "ARCHIVIOSTORICO", "BIBLIOGRAFIA", "CASSETTA", "COMUNE", "LAYER",  "MOVIMENTAZIONE", "REPERTO", "ROLE" o "USER")
// - The resourceGroups must be an array of objects with a name property (the groups associated to the resource)
// - The permission must be a string (i.e. "CREATE", "WRITE", "DELETE", or "READ"). NOTE: the user that can write can also create.

// THE ADMIN user can READ/CREATE/WRITE/DELETE ANY RESOURCE
// Other users need the required READ/WRITE/DELETE authority 
// for CASSETTA and REPERTO they need alto to be part of at least one of the groups associated to the resource

const userHasPermission = ({userProfile, resource, resourceGroups, permission}) => {
  if( !userProfile     
    || !Array.isArray(userProfile.roles)
    || !Array.isArray(userProfile.groups)
    || typeof resource !== 'string' 
    || typeof permission !== 'string' 
    || !["create", "write", "delete", "read"].includes(permission.toLowerCase())) return false;

  // ADMIN IS ALWAYS ALLOWED
  if(userProfile.roles.some((r) => r.authorities.some((aut) => aut === "ADMIN" ))) return true;

  // USER SHOULD HAVE THE REQUIRED PERMISSION
  if (!userProfile.roles.some((r) =>
    r.authorities.some((aut) => aut.toLowerCase() === `${resource.toLowerCase()}_${permission.toLowerCase()}`
  ))) return false;

  // USERS CAN'T EDIT OR DELETE A CASSETTA OR REPERTO IF THEY ARE NOT PART OF THE RESOURCE GROUPS
  if ( ["write", "delete"].includes(permission.toLowerCase()) && (resource.toLowerCase === "cassetta" || resource.toLowerCase === "reperto")) {
    if (!userProfile.groups.some((g) => resourceGroups.some((d) => d.name === g.name))) return false;
  }

  // USERS CAN'T CREATE A CASSETTA OR REPERTO IF THEY ARE NOT PART AT LEAST OF A GROUP
  if ( "create" === permission.toLowerCase() && (resource.toLowerCase === "cassetta" || resource.toLowerCase === "reperto")) {
    if (userProfile.groups.length === 0) return false;
  }

  return true;
};

export default userHasPermission;


