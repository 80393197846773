// Sort layers for better display on the maap

export const sortLayers = (layers) => {
  if(!Array.isArray(layers)) return [];

  const sortedLayers = layers.sort((a, b) => {
    const tipoA = a.tipo.toLowerCase();
    const tipoB = b.tipo.toLowerCase();

    if (tipoA === 'comune') return -1;
    if (tipoB === 'comune') return 1;

    if (tipoA === 'sito' || tipoA.includes('scav')) return -1;
    if (tipoB === 'sito' || tipoB.includes('scav')) return 1;

    return 0;
  });

  return sortedLayers;
}

export default sortLayers