import { useEffect, useState } from "react";
import { Accordion } from "../Accordion/Accordion";
import { OptionCard } from "../optionCard/optionCard";
import "./LayersCard.scss";

export const LayersCard = ({
  title = "",
  crossClickHandler = () => {},
  // layers,
  layersMenu,
  className = "",
  hasEyes = false,
  isCheckbox = false,
  children,
  handleCallBackLayerVisibility,
  handleCallBackCheckLayer,
  // handleCallBackLayerTitle,
  //handleCallBackTitle,
  mainTitle,
  layerTitle = "",
}) => {
  const layerGroups = [{ title: "Scavi", subChild: [] }];
  const [layersView, setLayersView] = useState(layerGroups);
  const [showBackground, setShowBackground] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);

  /*
  const handleState = (value) => {
    const subChildIndex = subChildTitle[0].subChild.findIndex(item => item.title === value.title);
    if (value.title === subChildTitle[0].title) {
      
      setSubChildTitle([{ title: subChildTitle[0].title, visible: !subChildTitle[0].visible, subChild: subChildTitle[0].subChild }]);
    } else {
      if (subChildIndex !== -1) {
        
        const updatedSubChild = subChildTitle[0].subChild.map((item, index) => {
          if (index === subChildIndex) {
            return { ...item, visible: !item.visible };
          }
          return item;
        });
        setSubChildTitle([{ ...subChildTitle[0], subChild: updatedSubChild }]);
      } else {
        
      }
    }
  };

  */

  // const handleCallBackEye = (value) => {
  //   const selected = layers.find((layer) => layer.tipo === value.title);
  //   if (selected) selected.vectorLayer.setVisible(value.eyeOpen);
  // };

  const handleCallBackLayerTitle = (value) => {};

  const handleCallBackTitle = (value) => {};

  return (
    <OptionCard
      title={title}
      className={className}
      childrenClassName="municipality-info-children-wrappper"
      onCrossClick={crossClickHandler}
    >
      <div className="layers--scrollable-wrapper">
        {layersMenu?.map(({ title, subChild, isChecked, isVisible }, idx) => (
          <Accordion
            title={title}
            mainTitle={mainTitle}
            layerTitle={layerTitle}
            isChecked={isChecked}
            isVisible={isVisible}
            isConnetorVisible
            childrenClassName="municipality-info-accordion"
            key={idx}
            defaultOpen
            isCheckbox={isCheckbox}
            hasEyes={hasEyes}
            handleCallBackVisibility={handleCallBackLayerVisibility}
            handleCallBackCheck={handleCallBackCheckLayer}
            handleCallBackLayerTitle={(value) => {
              handleCallBackLayerTitle(value);
            }}
            handleCallBackTitle={(value) => {
              handleCallBackTitle(value);
            }}
            dataList={layersView}
            showBackground={showBackground}
            setShowBackground={setShowBackground}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
          >
            {subChild?.map(
              ({ title, isVisible, subChild: subChild1 = [] }, idx) => (
                <div className="sub-accordion">
                  <Accordion
                    isOpenable={false}
                    title={title}
                    mainTitle={mainTitle}
                    layerTitle={layerTitle}
                    hasEyes={hasEyes}
                    isCheckbox={isCheckbox}
                    isChehed={isChecked}
                    isVisible={isVisible}
                    key={idx}
                    className="sub-accordion-wrapper"
                    handleCallBackVisibility={handleCallBackLayerVisibility}
                    handleCallBackCheck={handleCallBackCheckLayer}
                    handleCallBackLayerTitle={handleCallBackLayerTitle}
                    handleCallBackTitle={handleCallBackTitle}
                    showBackground={showBackground}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                  >
                    {subChild1?.map(({ title, isVisible }, idx) => (
                      <div className="sub-accordion">
                        <Accordion
                          handleCallBackCheck={handleCallBackCheckLayer}
                          handleCallBackVisibility={handleCallBackLayerVisibility}
                          handleCallBackLayerTitle={handleCallBackLayerTitle}
                          handleCallBackTitle={handleCallBackTitle}
                          isVisible={isVisible}
                          title={title}
                          mainTitle={mainTitle}
                          layerTitle={layerTitle}
                          className="sub-accordion-wrapper"
                          defaultOpen
                          isCheckbox={isCheckbox}
                          showBackground={showBackground}
                          selectedTitle={selectedTitle}
                          setSelectedTitle={setSelectedTitle}
                        />
                        {idx < subChild1?.length - 1 && (
                          <span className="node-line"></span>
                        )}
                      </div>
                    ))}
                  </Accordion>
                  {idx < subChild?.length - 1 && (
                    <span className="node-line"></span>
                  )}
                </div>
              )
            )}
          </Accordion>
        ))}
      </div>
      {children && <div className="layers-children-wrapper">{children}</div>}
    </OptionCard>
  );
};
