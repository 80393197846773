
// REACT IMPORTS
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// OPEN LAYER IMPORTS
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import "./openLayerMap.scss";
import XYZ from "ol/source/XYZ";
import { fromLonLat, get as getProjection, register, transform  } from "ol/proj";
import { Fill, Stroke, Style } from "ol/style";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { GeoJSON } from "ol/format";
import { isEmpty } from 'ol/extent';
import { Circle as CircleStyle } from "ol/style";
import { register as registerProj4 } from 'ol/proj/proj4';
import proj4 from "proj4";
import TileWMS from "ol/source/TileWMS.js";
import Select from "ol/interaction/Select.js";
import { click} from "ol/events/condition.js";
import MultiPolygon from "ol/geom/MultiPolygon";

// OTHER IMPORTS
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config"
import { replaceSubdomainPlaceholder } from "../../../helpers/gisTools/replaceSubdomainPlaceholder";
import { getComuneLayers, getComuni, getLayer } from "../../../api";
import init from "three/examples/jsm/offscreen/scene.js";
import { ProgressBar } from "../../index";
import mapStyle from "../../../helpers/gisTools/mapStyle";
import sortLayers from "../../../helpers/gisTools/sortLayers";



// CRS Definitions
//proj4.defs("EPSG:3857", "+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
proj4.defs("EPSG:32632", "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs");
proj4.defs( "EPSG:32633","+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs");
proj4.defs("EPSG:3857","+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs");
registerProj4(proj4);
const targetProjection = "EPSG:3857";


export const OLMap = ({
  backgroundMap, // Checked OK
  children, // ??
  className = "", // ??
  layers, // Checked OK
  mapHeight, // Checked OK
  mapViewTarget, // Checked OK
  handleFeatureProperties, // ??
  isEyeOpen, // ??
  title, // ??
  handleLegendImage,  // ??
  handleCallBackLayerTitle,  // ??
  selectedComune, // Checked OK
  setLayers, // Checked OK
  setSelectedComune, // Chedked OK
}) => {
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [names, setNames] = useState([]);
  const [legendUrl, setLegendUrl] = useState(null);
  const ambientiVectorLayerRef = useRef();
  const saggiVectorLayerRef = useRef();
  const sitiVectorLayerRef = useRef();
  const communiLayerRef = useRef();
  const [fetchedAllLayers, setFetchedAllLayers] = useState(null);
  const communiVectorSource = useRef(new VectorSource());
  const accessToken = localStorage.getItem("authToken");


  const crsTransformation = (sourceCrs, targetCrs, feature) => {
    const geometry = feature.geometry;
    let transformedCoordinates;
    const sourceProjection = getProjection(sourceCrs);
    
    // CHECK WEATHER THE SOURCE PROJECTION IS VALID
    if (!sourceProjection) {
      console.error(`Invalid projection: sourceCrs=${sourceCrs}`);
      return feature;
    }
    const targetProjection = getProjection(targetCrs);
    if (!targetProjection) {
      console.error(`Invalid projection: targetCrs=${targetCrs}`);
      return feature;

    }
    switch (geometry.type) {
      case 'Point':
        transformedCoordinates = (() => {
          try { return transform(geometry.coordinates, sourceCrs, targetCrs); }
          catch (error) { console.error(`error transforming ${geometry.type} coordinates`, error); }
        })();
        break;
      case 'MultiPoint':
        transformedCoordinates = (() => {
          try {  return geometry.coordinates.map(point => transform(point, sourceCrs, targetCrs)) }
          catch (error) { console.error(`error transforming ${geometry.type} coordinates`, error); }
        })()
      case 'LineString':
        transformedCoordinates = (() => {
          try { return geometry.coordinates.map(coord => transform(coord, sourceCrs, targetCrs)); }
          catch (error) { console.error(`error transforming ${geometry.type} coordinates`, error); }
        })();
        break;
      case 'MultiLineString':
        transformedCoordinates = (() => {
          try { return geometry.coordinates.map(line => line.map(coord => transform(coord, sourceCrs, targetCrs)));}
          catch (error) { console.error(`error transforming ${geometry.type} coordinates`, error); }
        })();
        break;
      case 'Polygon':
        transformedCoordinates = (() => {
          try { return geometry.coordinates.map(ring => ring.map(coord => transform(coord, sourceCrs, targetCrs))); }
          catch (error) { console.error(`error transforming ${geometry.type} coordinates`, error); }
        })();
        break;
      case 'MultiPolygon':
        transformedCoordinates = (() => {
          try {  return geometry.coordinates.map(polygon => polygon.map(ring => ring.map(coord => transform(coord, sourceCrs, targetCrs)))); }
          catch (error) { console.error(`error transforming ${geometry.type} coordinates`, error); }
        })()
        break;
      default:
        console.warn(`Unsupported geometry type: ${geometry.type}`);
        transformedCoordinates = geometry.coordinates;
    }
  
    return {
      ...feature,
      geometry: {
        ...geometry,
        coordinates: transformedCoordinates,
      },
    };
  };

  
  //********************************************************/
  // Fetching defaul minicipality (the forst time only)
  useEffect(() => {

    const findSelectedComune = async () => {
      try {
        const data = await getComuni([
          {key: "pageSize", value: 5},
          {key: "page", value: 1},
          {key: "nome", value: "Sibari"}]);
         if(data.results.length === 0) {
          throw new Error("The municipality with name Sibari was not found");
         }
         setSelectedComune(data.results.find((comune) => comune.nome === "Sibari"));
      } catch (error) {
        if(error.response && error.response.status === 401){
          console.error(`Unauthorized access to the "comuni" endpoint`);
        } else {
          toast.error(`Error fetching "comuni": ${error.message}`);
          console.error("Errore sconosciuto durante il recupero di siti e comuni");
        }
      }
    };
    if(!selectedComune) findSelectedComune();
  }, []);

 // ********************************************************************************
 // Fetching layers of the selected comune
 useEffect(() => {
  const listLayers = async (comuneId) => {
    try {
      var data = await getComuneLayers(comuneId);
      let newLayers = data.layers;
      if (newLayers.length === 0) {
        return;
      }

      var data = sortLayers(newLayers).map((layer) => {
        const vectorSource = new VectorSource(); 
        return {
          id: layer.id,
          tipo: layer.tipo,
          chiave: layer.chiave,
          group: layer.group,
          style: layer.style,
          crs: layer.crs,
          vectorSource: vectorSource,
          vectorLayer: new VectorLayer({
            source: vectorSource,
            projection: layer.crs,
            name: layer.tipo
          }),
        };
      });
      
      setLayers(data);
    } catch (error) {
      if(error.response && error.response.status === 401){
        navigate("/");
        throw new Error("Your session has ended. Redirecting you to the login page...");
      } else {
        toast.error(`Error fetching layers of municipality with id=${comuneId}: ${error.message}`);
        throw new Error("Network response was not ok");
      }
    }
  };
  if(selectedComune) listLayers(selectedComune.id);
}, [selectedComune]);

//*************************************************************** */
// Load Vector Source for each available layer
const fetchLayer = async (layer) => {
  try {
    const data = await getLayer(layer.id);
    const format = new GeoJSON();

    const transformedFeatures = data.features.map((feature) => crsTransformation(layer.crs, targetProjection, feature));

    const transformedGeoJSON = {
      type: "FeatureCollection",
      features: transformedFeatures,
    };

    let transformedOlFeatures = null;
    try {
      transformedOlFeatures = format.readFeatures(transformedGeoJSON, {
        dataProjection: targetProjection,
        featureProjection: targetProjection,
      });
    } catch (error) {
      console.error("error reading features", error);
    }
    console.error("transformedOlFeatures", transformedOlFeatures);

    try {
      await layer.vectorSource.clear();
    } catch (error) {
      console.error("error clearing vector source", error);
    }

    try {
      await layer.vectorSource.addFeatures(transformedOlFeatures);
    } catch (error) {
      console.error("error adding features", error);
    }

    try {
      await layer.vectorLayer.setStyle(mapStyle(layer, data.features[0].geometry.type),);
    } catch (error) {
      console.error(`error modifying layer style for ${layer.tipo} with geometry ${ data.features[0].geometry.type}`, error);
    }


    console.error("transformedOlFeatures added");
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigate("/");
      throw new Error("Your session has ended. Redirecting you to the login page...");
    } else {
      toast.error(`Error fetching layer: ${error.message}`);
      throw new Error("Network response was not ok");
    }
  }
};

// ************************************************************************************
// FETCH ALL LAYERS
useEffect(() => {
  async function fetchLayers() {
    try {
      for (const layer of layers) await fetchLayer(layer);
      setFetchedAllLayers(selectedComune);
    } catch (error) {
      console.warn("couldn't fetch layers.");
    }
  }
  fetchLayers();
}, [layers]);

  // ********************************************************************************
  // BUILD THE MAP
  useEffect(() => {
    async function initializeMap() {
      const center = [0, 0];
      let mapLayers = [
        new TileLayer({
          source: new XYZ({
            url: replaceSubdomainPlaceholder({url: backgroundMap.url, subdomains: backgroundMap.subdomains }),
            attributions: '...',
            maxZoom: backgroundMap.maxZoom ? backgroundMap.maxZoom : 19,
            crossOrigin: 'anonymous',
          }),
        })
      ]
      /*
      try {
        for (const layer of layers) await fetchLayer(layer);
      } catch (error) {
        console.warn("couldn't fetch layers.");
      }
      */
      layers.forEach((layer) => {
        mapLayers.push(layer.vectorLayer);
      });
    
      const mapInstance = new Map({
        target: mapRef.current ?? "",
        layers: mapLayers,
        view: new View({
          center: fromLonLat(center, targetProjection),
          zoom: 2,
          projection: getProjection(targetProjection)  
        }),
      });
      // Zoom to the extent of the selected feature
      const layerType =  mapViewTarget?.layer ? mapViewTarget.layer : "comune";
      const target =  mapViewTarget?.layer ? mapViewTarget.layer : "comune";
      if (mapLayers.length > 1) {
        const targetLayer = layers.find((layer) => layer.tipo === layerType);
        let targetFeature = null;
        if (mapViewTarget.key && mapViewTarget.value) {
          targetFeature = targetLayer.vectorSource.getFeatures().find((feature) => feature.get(mapViewTarget.key) === mapViewTarget.value);
        }
        const source = targetLayer.vectorSource;
        let extent = null;
        if (targetFeature) {
          extent = targetFeature.getGeometry().getExtent();
        } else {
          const source = targetLayer.vectorSource;
          extent = source.getExtent();
        }
        if (extent && !isEmpty(extent)) {
          mapInstance.getView().fit(extent, { duration: 1000 });
        } else {
          console.warn("The extent of the target layer is empty.", mapLayers[1]);
        }
      }
      setMap(mapInstance);

      // getting feature property
      const handleMapClick = (evt) => {
        const feature = mapInstance.forEachFeatureAtPixel(
           evt.pixel,
          (feature) => feature
        );
        if (feature) {
          const props = feature.getProperties();
          const layer = mapInstance.getLayerGroup().getLayers().getArray().find(l => {
             const source = l.getSource()
             return source instanceof VectorSource && source.getFeatures().includes(feature);
          });
          const layerProps = layer ? layer.getProperties() : {};
          handleFeatureProperties({ ...props, layerName: layerProps.name });
          }  else { 
          handleFeatureProperties({});
        }
      };

      // getting layer property and condition based map Interactions
      const handleLayerClick = (evt) => {
        let clickedFeature = null;

        mapInstance.getInteractions().forEach((interaction) => {
          if (interaction instanceof Select) {
            mapInstance.removeInteraction(interaction);
          }
        });
        mapInstance.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
          if (feature && !clickedFeature) {
            clickedFeature = feature;
            const layerTitle = layer.get("title");
            handleCallBackLayerTitle(layerTitle);

            const selected = new Style({
              fill: new Fill({
                color: "#00FFFF",
              }),
              stroke: new Stroke({
                color: "rgba(255, 255, 255, 0.7)",
                width: 5,
              }),
            });

            // Check if layerTitle matches the title prop
            if (
              layerTitle
            ) {
              function selectStyle(feature) {
                const color = feature.get("COLOR") || "#00FFFF";
                selected.getFill().setColor(color);
                return selected;
              }

              const selectClick = new Select({
                condition: click,
                style: selectStyle,
              });
              mapInstance.on("click", handleMapClick);
              mapInstance.addInteraction(selectClick);
            } else {
              // Remove the event listener if not needed
              mapInstance.on("click", handleMapClick);
            }
          }
        });
      };

      mapInstance.on("click", handleLayerClick);
      // sitiVectorSource.current.on("addfeature", () => {
      //   const extent = sitiVectorSource.current.getExtent();
      //   mapInstance.getView().fit(extent, { duration: 1000 });
      // });
      // ambientiVectorSource.current.on("addfeature", () => {
      //   const extent = ambientiVectorSource.current.getExtent();
      //   mapInstance.getView().fit(extent, { duration: 1000 });
      // });
      communiVectorSource.current.on("addfeature", () => {
        const extent = communiVectorSource.current.getExtent();
        mapInstance.getView().fit(extent, { duration: 1000 });
      });
    };
    
    /*
    if (!wmsLayerRef.current) {
      wmsLayerRef.current = new TileLayer({
        source: new TileWMS({
          url: config.geoserverUrl + "/wms",
          params: {
            LAYERS: "test-archeo:Lerici_nc1",
            TILED: true,
            FORMAT: "image/png",
          },
          serverType: "geoserver",
        }),
      });
    }
      */

    /*
    if (!wmsLayerRef2.current) {
      wmsLayerRef2.current = new TileLayer({
        source: new TileWMS({
          url: config.geoserverUrl + "/wms",
          params: {
            LAYERS: "test-archeo:Quilici_Sibari",
            TILED: true,
            FORMAT: "image/png",
          },
          serverType: "geoserver",
        }),
      });
    }
      */
    
    // if (!map) {
    //   initializeMap();
    // }

    // return () => {
    //   if (map) {
    //     map.setTarget("");
    //   }
    // };
    initializeMap();

    return () => {
      if (mapRef.current) {
        mapRef.current.innerHTML = "";
      }
    };

    // eslint-disable-next-line
  }, [backgroundMap, fetchedAllLayers]);

  //Remove wmsLayer if sibari municipality is not selected. 
  /*
  useEffect(() => {
    if (map) {
    
      if (!selectedComune) {
        
        if (wmsLayerRef.current) {
          map.removeLayer(wmsLayerRef.current);
        }
        if (wmsLayerRef2.current) {
          map.removeLayer(wmsLayerRef2.current);
        }
      } 
    
  
    // Cleanup function
    
    return () => {
      if (map) {
        
        if (wmsLayerRef.current) {
          map.removeLayer(wmsLayerRef.current);
        }
        if (wmsLayerRef2.current) {
          map.removeLayer(wmsLayerRef2.current);
        }
      }
    };
  }, [municipalityId, map]);
  */
 



  // Layer Visibility
  useEffect(() => {
    if (communiLayerRef.current && title === names[0]) {
      communiLayerRef.current.setVisible(isEyeOpen);
      ambientiVectorLayerRef.current.setVisible(isEyeOpen);
      saggiVectorLayerRef.current.setVisible(isEyeOpen);
      //wmsLayerRef2.current.setVisible(isEyeOpen);
      //wmsLayerRef.current.setVisible(isEyeOpen);
      sitiVectorLayerRef.current.setVisible(isEyeOpen);
    }
    if (ambientiVectorLayerRef.current && title === names[2]) {
      ambientiVectorLayerRef.current.setVisible(isEyeOpen);
    }
    if (saggiVectorLayerRef.current && title === names[3]) {
      saggiVectorLayerRef.current.setVisible(isEyeOpen);
    }
    if (sitiVectorLayerRef.current && title === names[1]) {
      sitiVectorLayerRef.current.setVisible(isEyeOpen);
    }
    //if (wmsLayerRef2.current && title === "Quilici_Sibari") {
    //  wmsLayerRef2.current.setVisible(isEyeOpen);
    //}
    //if (wmsLayerRef.current && title === "Lerici_nc1") {
     // wmsLayerRef.current.setVisible(isEyeOpen);
    //}
  }, [isEyeOpen, title, names]);



  //****************************** */
  // HADNLING LEGEND
  /*
  useEffect(() => {
    updateLegend();
  }, [
    communiLayerRef.current,
    ambientiVectorLayerRef.current,
    saggiVectorLayerRef.current,
    sitiVectorLayerRef.current,
  ]);

  /*
  const updateLegend = () => {
    // Define a canvas element to draw the legend
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas size
    canvas.width = 200;
    canvas.height = 200;

    // Define legend items
    
    let offsetY = 20;
    Object.keys(layersStyle).forEach((key) => {
      // Drawing  a rectangle with layer stroke color
      ctx.strokeStyle = layersStyle[key].stroke.color;
      ctx.lineWidth =  layersStyle[key].stroke.width;
      ctx.strokeRect(20, offsetY - 10, 30, 20);
      // Drawing layer label
      ctx.font = "12px Arial";
      ctx.fillText(key, 60, offsetY);
      offsetY += 30;
    });

    handleLegendImage(legendUrl);
    // Seting legend URL as a data URL
    setLegendUrl(canvas.toDataURL());
  };
  */

  return (
    <div className="open-layer-map-main-container">
      <ProgressBar isAnimating={!fetchedAllLayers} />
      <div
        className={`open-layer-map-sub-container ${className}`}
        style={{ height: mapHeight }}
        ref={mapRef}
      ></div>

      {children}
    </div>
  );
};
