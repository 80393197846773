import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { useAppContext } from "../../Context/AppContext";
import { newArchivio } from "../../../api/archivio";
import DataTableSubHeader from "../../DataTableSubHeader/DataTableSubHeader";
import { AddIcon, CrossIcon } from "../../../icons";
import archivioStructure from "../../../constants/dataStructure/archivio.json";
import validateArchivio from "../../../helpers/formTools/validations/archivio/validateArchivio";
import { useCheckRole } from "../../../hooks/useCheckRole";
import ArchivoDetailFrom from "../ArchivoDetailForm/ArchivoDetailForm";
import "./AddArchivio.scss";

const archivioRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "ARCHIVIO STORICO",
    iconUrl: true,
  },
  { id: 3, label: "Add ARCHIVIO STORICO Record Details", iconUrl: "" },
];

const AddArchivio = () => {
  const navigate = useNavigate();
  const { setArchivioFilter } = useAppContext();

  const [isMenuActive, setMenuActive] = useState(false);
  const [formData, setFormData] = useState({});
  const [showIissues, setShowIssues] = useState(false);

  useCheckRole("ARCHIVIO_WRITE");

  const issues = useMemo(() => validateArchivio(formData), [formData]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, id: uuidv4() }));
  }, []);

  const newArchivioRecord = useCallback(
    async (data) => {
      try {
        await newArchivio(data);
        toast.success("Data updated successfully!");
        navigate("/archivio");
      } catch (error) {
        toast.error("Something went wrong, please try again!");
      }
    },
    [navigate]
  );

  const handleFormDataChange = useCallback(
    (key, value) => {
      setFormData((prev) => ({ ...prev, [key]: value }));
    },
    [setFormData]
  );

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(formData.id);
    toast.success("ID copied to clipboard!");
  }, [formData]);

  const handleSave = useCallback(async () => {
    console.log("formData", formData);
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);

    try {
      await newArchivioRecord(formData);
      setArchivioFilter([]);
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    }
  }, [formData, issues, setArchivioFilter, navigate, newArchivioRecord]);

  const subHeaderRightNavOptions = {
    icons: [],
    components: [
      <button onClick={() => navigate(-1)} className="cancel-button">
        <CrossIcon />
        <span className="login-text">Cancel</span>
      </button>,
      <button
        onClick={() => {
          handleSave();
        }}
        className="save-button"
      >
        <AddIcon fillColor="#fff" />
        <span className="login-text">Save</span>
      </button>,
    ],
  };

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"ARCHIVIO STORICO Record Details"}
          breadcrumbsData={archivioRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <ArchivoDetailFrom
          showIssues={showIissues}
          issues={issues}
          formData={formData}
          archivioStructure={archivioStructure}
          copyToClipboard={copyToClipboard}
          handleFormDataChange={handleFormDataChange}
        />
      </main>
    </div>
  );
};

export default AddArchivio;
