import httpArcheo from "../helpers/http-archeo";

export const getArchivio = async () => {
  try {
    const response = await httpArcheo.get(
      "/api/private/v3/archivio/storico/get"
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getArchivioById = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/archivio/storico/get/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const newArchivio = async (data) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/archivio/storico/save",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updateArchivio = async (data) => {
  try {
    const response = await httpArcheo.put(
      "/api/private/v3/archivio/storico/update",
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const deleteArchivio = async (id) => {
  try {
    const response = await httpArcheo.delete(
      `/api/private/v3/archivio/storico/delete/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
