import FileUploadSection from "../../../FileUploadSection/FileUploadSection";
import AssociationSection from "../AssociationSection/AssociationSection";
import OriginSection from "../OriginSection/OriginSection";
import InventoryCodeSection from "../InventoryCodeSection/InventoryCodeSection";
import CustomReactSelect from "../../../CustomReactSelect/CustomReactSelect";
import { inventoryCodes as inventoryCodeTypes } from "../../../../constants/inventoryCodes";
import "./MatchSection.scss";

const MatchSection = ({
  headers,
  groups,
  group,
  tipoJson,
  origins = [],
  inventoryCodes = [],
  handleTipoChange,
  handleKeyChange,
  handleSelectChange,
  handleInputChange,
  handleAssocChange,
  handleCSVXlsUpload,
  handleAddInventoryCode,
  handleAddOrigin,
  handleDeleteOrigin,
  handleDeleteInventory,
  handleGroupChange
}) => {
  return (
    <div className="modal-upload-section-container">
      <FileUploadSection handleUpload={handleCSVXlsUpload} acceptType={".xls,.csv,.xlsx"} />
      {headers.length ? (
        <div className="modal-upload-section-content">
          <div className="group-selection-section">
            <CustomReactSelect
              options={groups}
              displayAttr={"name"}
              value={group}
              label="Gruppo di lavoro"
              placeholder="Seleziona gruppo di lavoro"
              onChange={handleGroupChange}
            />
          </div>
          <div className="modal-upload-match-section">
            <AssociationSection
              headers={headers}
              handleChange={handleAssocChange}
            />
          </div>
          <div className="origin-add-btn-wrapper">
            <button onClick={handleAddInventoryCode}>
              Add Inventory Codes
            </button>
          </div>
          {inventoryCodes?.length
            ? inventoryCodes?.map((origin, index) => (
                <InventoryCodeSection
                  key={index}
                  inventoryCodeNo={index}
                  inventoryCode={origin}
                  tipoJson={inventoryCodeTypes}
                  headers={headers}
                  handleTipoChange={handleTipoChange}
                  handleSelectChange={handleSelectChange}
                  handleInputChange={handleInputChange}
                  handleDeleteInventory={handleDeleteInventory}
                />
              ))
            : null}
          <div className="origin-add-btn-wrapper">
            <button onClick={handleAddOrigin}>Add Origins</button>
          </div>
          {origins?.length
            ? origins?.map((origin, index) => (
                <OriginSection
                  key={index}
                  originNo={index}
                  origin={origin}
                  tipoJson={tipoJson}
                  headers={headers}
                  handleTipoChange={handleTipoChange}
                  handleSelectChange={handleSelectChange}
                  handleInputChange={handleInputChange}
                  handleKeyChange={handleKeyChange}
                  handleDeleteOrigin={handleDeleteOrigin}
                />
              ))
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default MatchSection;
