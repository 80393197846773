import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PopupItem from "../PopUpList/PopUpList";
import { EyeIcon } from "../../icons/eyeIcon";
import {
  extractIds,
  listToString,
} from "../../helpers/formTools/transformations";

const FormField = ({
  fieldStructure,
  value,
  disabled,
  onChange,
  handleCallbackListFormData,
  handleCallbackAddToListFormData,
}) => {
  const label = fieldStructure ? fieldStructure.label : " ";
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [currentHeaderKey, setCurrentHeaderKey] = useState("");
  const [currentListOfItems, setCurrentListOfItems] = useState([""]); // list of items
  const [currentItemsToBeAdded, setCurrentItemsToBeAdded] = useState([]); // new typing data

  /*
    useEffect(() => {
      if(fieldStructure && fieldStructure.isList === true) {
        if (fieldStructure.listType === "objects") {
          setCurrentListOfItems(extractIds({objectArray: value, objectId: fieldStructure.objId })); 
        } else setCurrentListOfItems(value); 
      }
    }, [value]);
    */

  const location = useLocation();
  const { pathname } = location;
  // Input change for Overall form

  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  const handleFieldChange = (index, newValue) => {
    setCurrentListOfItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = newValue;
      return updatedItems;
    });
  };

  // POPUP OF A LIST OF ITEMS
  const handlePopUp = (value, fieldStructure) => {
    setCurrentHeaderKey(fieldStructure.label);
    if (fieldStructure && fieldStructure.isList === true) {
      if (fieldStructure.listType === "objects") {
        setCurrentListOfItems(
          extractIds({ objectArray: value, objectId: fieldStructure.objId })
        );
      } else setCurrentListOfItems(value);
      setPopupOpen(true);
    }
  };

  //save list
  const handleSaveList = () => {
    setPopupOpen(false);
    setIsAddPopupOpen(false);
    handleCallbackListFormData([...currentListOfItems], label);
  };

  // save add
  const handleAddItem = () => {
    setPopupOpen(false);
    setIsAddPopupOpen(false);
    handleCallbackAddToListFormData(
      [...currentListOfItems, ...currentItemsToBeAdded],
      label
    );
  };

  // popup for add
  const handleAddPopUp = (label) => {
    setIsAddPopupOpen(true);
    setCurrentHeaderKey(label);
    setCurrentItemsToBeAdded((prev) => [...prev, ""]);
  };

  // field change for Add
  const handleFieldAddition = (index, newValue) => {
    setCurrentItemsToBeAdded((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = newValue;
      return updatedItems;
    });
  };

  //close button event for all
  const handleClosePopup = () => {
    setPopupOpen(false);
    setIsAddPopupOpen(false);
  };

  const handleAddField = () => {
    setCurrentItemsToBeAdded((prevItems) => [...prevItems, ""]);
  };

  // Function to handle removing an input field for Ambienti
  const handleRemoveItem = (index) => {
    setCurrentItemsToBeAdded((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  //// Delete

  const handleDeleteField = (index) => {
    if (pathname === "/cassetteRecord/edit") {
      setCurrentItemsToBeAdded((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
      setCurrentListOfItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
  };

  return (
    <>
      <div className="input-field">
        <div className="field-header">
          <div className="field-label"> {label}</div>
          {fieldStructure && fieldStructure.isList === true && (
            <div className="icon-box">
              <svg
                width="36"
                height="36"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handlePopUp(value, fieldStructure)}
              >
                <path
                  d="M21.25 9.14993C18.94 5.51993 15.56 3.42993 12 3.42993C10.22 3.42993 8.49 3.94993 6.91 4.91993C5.33 5.89993 3.91 7.32993 2.75 9.14993C1.75 10.7199 1.75 13.2699 2.75 14.8399C5.06 18.4799 8.44 20.5599 12 20.5599C13.78 20.5599 15.51 20.0399 17.09 19.0699C18.67 18.0899 20.09 16.6599 21.25 14.8399C22.25 13.2799 22.25 10.7199 21.25 9.14993ZM12 16.0399C9.76 16.0399 7.96 14.2299 7.96 11.9999C7.96 9.76993 9.76 7.95993 12 7.95993C14.24 7.95993 16.04 9.76993 16.04 11.9999C16.04 14.2299 14.24 16.0399 12 16.0399Z"
                  fill="#E15615"
                />
                <path
                  d="M12.0004 9.13989C10.4304 9.13989 9.15039 10.4199 9.15039 11.9999C9.15039 13.5699 10.4304 14.8499 12.0004 14.8499C13.5704 14.8499 14.8604 13.5699 14.8604 11.9999C14.8604 10.4299 13.5704 9.13989 12.0004 9.13989Z"
                  fill="#E15615"
                />
              </svg>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleAddPopUp(label)}
              >
                <path
                  d="M14.0007 2.33337C7.57232 2.33337 2.33398 7.57171 2.33398 14C2.33398 20.4284 7.57232 25.6667 14.0007 25.6667C20.429 25.6667 25.6673 20.4284 25.6673 14C25.6673 7.57171 20.429 2.33337 14.0007 2.33337ZM18.6673 14.875H14.8757V18.6667C14.8757 19.145 14.479 19.5417 14.0007 19.5417C13.5223 19.5417 13.1257 19.145 13.1257 18.6667V14.875H9.33398C8.85565 14.875 8.45898 14.4784 8.45898 14C8.45898 13.5217 8.85565 13.125 9.33398 13.125H13.1257V9.33337C13.1257 8.85504 13.5223 8.45837 14.0007 8.45837C14.479 8.45837 14.8757 8.85504 14.8757 9.33337V13.125H18.6673C19.1457 13.125 19.5423 13.5217 19.5423 14C19.5423 14.4784 19.1457 14.875 18.6673 14.875Z"
                  fill="#E15615"
                />
              </svg>
            </div>
          )}
        </div>
        <input // value displayed in the form field
          disabled={disabled}
          className="input-value"
          type={fieldStructure?.type}
          value={
            fieldStructure && fieldStructure.isList === true
              ? fieldStructure.listType === "objects"
                ? listToString(
                    extractIds({
                      objectArray: value,
                      objectId: fieldStructure.objId,
                    })
                  )
                : listToString(value)
              : value
          }
          onChange={handleInputChange}
        />
      </div>

      {isPopupOpen /*&& currentHeaderKey */ && (
        <div id="popup" className="popup">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1aa869fbf5e1c122ce5cc6a909400ac6b987db1493d224e0b2e456558ffb783a?apiKey=d03ff6b018f84c75b88104249d2053b6&"
            alt="close-button"
            onClick={handleClosePopup}
          />
          <div id="popupdata">
            {
              currentListOfItems.length > 0 &&
                currentListOfItems.map((value, index) => (
                  <div key={index}>
                    <PopupItem
                      type="text"
                      value={value}
                      onChange={(newValue) =>
                        handleFieldChange(index, newValue)
                      }
                      disabled={disabled}
                    />
                    {pathname === "/cassetteRecord/edit" && index >= 0 && (
                      <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/93f201e82a5c2f4b48e4ff1aef6f65d069ae967ba0dca27df7571870cd72a0ed?apiKey=d03ff6b018f84c75b88104249d2053b6&"
                        alt="Remove-button"
                        onClick={() => handleDeleteField(index)}
                      />
                    )}
                    {fieldStructure.gisReference === true ? (
                      <a
                        href="/Mapview"
                        className="web-gis-link"
                        onClick={() => {
                          const lidValue = index;
                          if (lidValue !== null) {
                            localStorage.setItem("ambientiLid", lidValue);
                            localStorage.removeItem("sitiLid");
                            localStorage.removeItem("saggiLid");
                            localStorage.removeItem("repertiLid");
                          }
                        }}
                        style={{
                          display: "block",
                          margin: "10px 0",
                          fontSize: "14px",
                          color: "#E15615",
                          textDecoration: "none",
                        }}
                      >
                        Web GIS
                      </a>
                    ) : null}
                  </div>
                )) // end of map
            }
          </div>
        </div>
      )}

      {isAddPopupOpen &&
        currentHeaderKey &&
        pathname === "/cassetteRecord/edit" && (
          <div id="popup" className="popup">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1aa869fbf5e1c122ce5cc6a909400ac6b987db1493d224e0b2e456558ffb783a?apiKey=d03ff6b018f84c75b88104249d2053b6&"
              alt="close-button"
              onClick={handleClosePopup}
            />
            <>
              {currentItemsToBeAdded.map((value, index) => (
                <div key={index}>
                  <PopupItem
                    type="text"
                    value={value}
                    onChange={(newValue) => handleAddItem(index, newValue)}
                  />
                  {index >= 0 && (
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/93f201e82a5c2f4b48e4ff1aef6f65d069ae967ba0dca27df7571870cd72a0ed?apiKey=d03ff6b018f84c75b88104249d2053b6&"
                      alt="Remove-button"
                      onClick={() => handleRemoveItem(index)}
                    />
                  )}
                </div>
              ))}

              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleAddItem}
              >
                <path
                  d="M14.0007 2.33337C7.57232 2.33337 2.33398 7.57171 2.33398 14C2.33398 20.4284 7.57232 25.6667 14.0007 25.6667C20.429 25.6667 25.6673 20.4284 25.6673 14C25.6673 7.57171 20.429 2.33337 14.0007 2.33337ZM18.6673 14.875H14.8757V18.6667C14.8757 19.145 14.479 19.5417 14.0007 19.5417C13.5223 19.5417 13.1257 19.145 13.1257 18.6667V14.875H9.33398C8.85565 14.875 8.45898 14.4784 8.45898 14C8.45898 13.5217 8.85565 13.125 9.33398 13.125H13.1257V9.33337C13.1257 8.85504 13.5223 8.45837 14.0007 8.45837C14.479 8.45837 14.8757 8.85504 14.8757 9.33337V13.125H18.6673C19.1457 13.125 19.5423 13.5217 19.5423 14C19.5423 14.4784 19.1457 14.875 18.6673 14.875Z"
                  fill="#E15615"
                />
                {currentHeaderKey.charAt(0).toUpperCase() +
                  currentHeaderKey.slice(1)}
              </svg>

              <button
                onClick={handleSaveList}
                className="save-button"
                style={{
                  padding: "5px",
                  paddingRight: "20px",
                  fontSize: "12px",
                }}
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/844383194e1cab7b816cafd681f32eb8047cd9ae7c3bc597cc1bb9e19b52342a?apiKey=d03ff6b018f84c75b88104249d2053b6&"
                  alt="Save icon"
                  className="login-icon"
                />
                <span className="login-text">Save</span>
              </button>
            </>
          </div>
        )}
    </>
  );
};

export default React.memo(FormField);
