import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Reperti.scss";
import ReactModal from "../ReactModal/ReactModal";
import ItemsTable from "../ItemsTable/ItemsTable.jsx";
import DataDropdown from "../DataDropdown";
import RepertiRecordFileUploadModal from "./RepertiRecordFileUploadModal/RepertiRecordFileUploadModal";
import FilterModal from "../FilterModal";
import DataTableSubHeader from "../DataTableSubHeader/DataTableSubHeader";
import { SubHeaderMobileOption } from "../index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddIcon,
  CloudIcon,
  DeleteIcon,
  DownloadIcon,
  EyeIcon,
  FilterIcon,
} from "../../icons/index.js";
import datastructure from "../../constants/dataStructure/reperto.json";
import { useAppContext } from "../Context/AppContext";
import { getReperti, getComuni, deleteReperto } from "../../api";
import jsonToXlsx from "../../helpers/formTools/transformations/jsonToXlsx";
import userHasPermission from "../../helpers/formTools/userHasPermission";

function Reperti() {
  const navigate = useNavigate();
  const { repertiFilter, setRepertoId, setRepertiFilter, setComuni, userProfile } =
    useAppContext();
  const [isEditing, setEditing] = useState(false);
  const [data, setData] = useState([]);
  const [convertedData, setConvertedData] = useState([]);
  const [isMenuActive, setMenuActive] = useState(false);
  const [recordFileUploadModalOpen, setRecordFileUploadModalOpen] =
    useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [recordFilterModalOpen, setRecordFilterModalOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [totalRecords, setTotalRecords] = useState(0);
  const filterRef = useRef(false);
  const [isReset, setIsReset] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const userCanCreate = userHasPermission({userProfile, resource: "CASSETTA", permission: "CREATE"});


  /* ************************************************************ */
  // ALL API CALLS




  const listComuni = async () => {
    try {
      const comuni = await getComuni([
        { key: "pageSize", value: 1000 },
        { key: "page", value: 1 },
      ]);
      if (Array.isArray(comuni.results)) {
        setComuni(comuni.results);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        throw new Error(`Authorization error fetching "comuni"`);
      } else {
        toast.error(`Errore di connessione`);
      }
    }
  };

  async function listReperti() {
    const initialFilter = [
      { key: "page", value: currentPage },
      { key: "pageSize", value: recordsPerPage },
    ];
    const filter = [...initialFilter, ...repertiFilter];

    try {
      const reperti = await getReperti(filter);
      setData(reperti.results);
      setTotalRecords(reperti.totalElements);
      setTotalPages(reperti.totalPages);
      return true;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        toast.error(`Errore di connsessione su "reperti"`);
      } else {
        console.error(`Error listing "reperti"`, error);
        toast.error(`Errore sconosciuto ("reperti")`);
      }
    }
  }

  async function removeReperto(id) {
    try {
      const response = await deleteReperto(id);
      toast.success("Reperto cancellato con successo");
      setTimeout(() => {
        window.location.reload();
      }, 20); // 2000 milliseconds = 2 seconds
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        toast.error(`Errore di autenticazione nella rimozione di un reperto`);
      } else {
        console.error(`Error deleting "reperto" record`, error);
        toast.error(`Errore sconosciuto nella rimozione di un reperto`);
      }
    }
  }

  /* ********************************************************* */
  // ALL EFFECTS

  // GET THE UPDATED LIST OF MUNICIPALITIES
  useEffect(() => {
    listComuni();
    return () => {};
  }, []);

  // HERE YOU FETCH UPDATED DATA FROM REPERTI
  useEffect(() => {
    listReperti();
    return () => {};
  }, [repertiFilter, currentPage, recordsPerPage, sortDirection]);

  // HERE YOU CONVERT THE DATA FOR THE TABLE
  useEffect(() => {
    if (!Array.isArray(data)) return;
    let mappedData = []; // Initialize the array to store the converted data
    if (Array.isArray(data))
      data.forEach((result, index) => {
        let jsonObject = {}; // Initialize the row object
        jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

        // Convert row attributes in the right format base on a key and a value to be displayed
        for (const key in result) {
          if (result.hasOwnProperty(key)) {
            let value;
            if (datastructure[key] && datastructure[key].type === "object") {
              value = result[key][datastructure[key].objectName];
            } else {
              value =
                result[key] !== undefined && result[key] !== null
                  ? result[key].toString()
                  : "";
            }
            jsonObject[key] = {
              value,
            };
          }
          // SPECIAL CASE SITO
          let sito = null;
          if (key === "provenienze") {
            sito = result[key].find((o) => o.tipo === "scavo");
            console.log(">>>>> sito", sito);  
            if (sito) jsonObject["sito"] = { value: sito.valore };
            else jsonObject["sito"] = { value: "-" };
          }

          // SPECIAL CASE CASSETTA
          if (key === "raccolta" && result[key]) jsonObject["cassetta"] = { value: result[key].cassettaId };

          // SPECIAL CASE REPERTORI
          if (key === "codiciInventario" && result[key]) jsonObject["repertori"] = { value: result[key].map((o) => o.codice).join(", ") }

        }

        // Identifies the ID of the record
        const Id = jsonObject.id.value.replace(/^"|"$/g, "");

        // Add the actions to the row
        jsonObject.actions = {
          value: [],
          component: (
            <>
              <div
                onClick={() => {
                  navigate("/reperti/view");
                  setRepertoId(Id);
                }}
                className="row-icon"
                style={{ cursor: "pointer" }}
              >
                <EyeIcon />
              </div>
              { userHasPermission({userProfile, resource: "CASSETTA", resourceGroups: result.groups, permission: "DELETE"}) && (<div
                onClick={() => removeReperto(Id)}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </div>)}
            </>
          ),
        };
        mappedData.push(jsonObject);
      });
    setConvertedData(mappedData);
    return () => {};
  }, [data]);

  /* ********************************************************** */
  // ALL HANDLERS

  const handleCallBackFilter = (data) => {
    const filter = data.map((item) => {
      return { key: filterValues.find( f => f.label === item.label).key, value: item.value };
    });
    setRepertiFilter(filter); // Update filterValue state
    return true;
  };

  const handleReset = (resetStatus) => {
    setIsReset(resetStatus);
    if (resetStatus) {
      // Perform additional actions if needed when reset is true
      filterRef.current = false;
    }
  };

  const downloadCSVData = (e) => {
    const jsonArr = data.map(({ actions, ...rest }) => rest);
    // const csvData = jsonToCSV(jsonArr);
    // const csvFile = new Blob([csvData], { type: 'text/csv' });
    // const downloadLink = document.createElement('a');
    // downloadLink.download = 'Cassette.csv';
    // downloadLink.href = window.URL.createObjectURL(csvFile);
    // downloadLink.style.display = 'none';
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    jsonToXlsx(jsonArr, "reperti.xlsx");
  };

  async function handleCallBackSort(key) {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    setRepertiFilter([{ key: "sort", value: `${key}, ${sortDirection}` }]);
  }

  const handleFileUploadIconClick = (e) => {
    setRecordFileUploadModalOpen(true);
  };

  const handleFilterIconClick = (e) => {
    setRecordFilterModalOpen(true);
  };

  const closeRecordFileUploadModal = () => {
    setRecordFileUploadModalOpen(false);
  };

  const closeRecordFilterModal = () => {
    setRecordFilterModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRecordsPerPageChange = (newRecordsPerPage) => {
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); // Reset to first page when records per page changes
  };

  const handleSetRepertiFilter = (filter) => {
    console.log("==| handleSetRepertiFilter", filter);
  };

  /* ********************************************************** */
  // NAVIGATION

  const breadcrumbsData = [
    { id: 1, label: "Dati Archeologici", iconUrl: true },
    { id: 2, label: "Reperti" },
  ];

  const subHeaderRightNavOptions = {
    icons: [
      userCanCreate && {
        icon: <CloudIcon />,
        onClick: handleFileUploadIconClick,
        label: "Upload",
      },
      userCanCreate && {
        icon: <AddIcon />,
        label: "Add",
        onClick: () => {
          navigate("/reperti/add");
          return null;
        },
      },
      { icon: <FilterIcon />, onClick: handleFilterIconClick, label: "Filter" },
      {
        icon: <DownloadIcon />,
        label: "Download",
        onClick: () => {
          downloadCSVData();
        },
      },
    ],
    components: [<DataDropdown defaultSelected="REPERTI" />],
  };

  /* *********************************************************** */
  // POSSIBLE FILTERS
  const filterValues = [
    { label: "id", key: "id" },
    { label: "anno", key: "anno" },
    { label: "classe", key: "classe" },
    { label: "forma", key: "forma" },
    { label: "materiale", key: "materia" },
    { label: "repertorio", key: "codiceInventario.codice" },
    { label: "tipo", key: "tipo" },
  ];

  /* ********************************************************** */
  // TABLE HEADERS
  const headers = [
    { key: "actions", label: "actions", width: "100px", sortable: false },
    { key: "forma", label: "Forma", width: "10rem", sortable: false },
    { key: "classe", label: "Classe", width: "10rem", sortable: false },
    { key: "cassetta", label: "Cassetta", width: "10rem", sortable: false },
    { key: "sito", label: "Area di scavo", width: "10rem", sortable: false },
    { key: "repertori", label: "Codici di inventario", width: "20rem", sortable: false },
  ];

  /* ********************************************************* */

  return (
    <>
      <div className="container">
        <main className="group-detail-main-content">
          <DataTableSubHeader
            title={"REPERTI"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <ReactModal
            isOpen={recordFileUploadModalOpen}
            onClose={closeRecordFileUploadModal}
          >
            <RepertiRecordFileUploadModal
              closeModal={closeRecordFileUploadModal}
            />
          </ReactModal>
          <ReactModal
            isOpen={recordFilterModalOpen}
            onClose={closeRecordFilterModal}
          >
            <FilterModal
              closeModal={closeRecordFilterModal}
              filterValues={filterValues}
              setFilterValue={setFilterValue}
              callBackFilter={handleCallBackFilter}
              onReset={handleReset}
            />
          </ReactModal>
          <ItemsTable
            data={convertedData}
            headers={headers}
            filterValues={filterValues}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            totalRecords={totalRecords}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
            dataResult={dataResult}
            callBackSort={handleCallBackSort}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={!isEditing ? subHeaderRightNavOptions?.components : []}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default Reperti;
