export const FloorIcon = ({
  height = "22px",
  width = "22px",
  fillColor = "#fff",
}) => {
  return (
     <svg 
        id="Floorplan--Streamline-Carbon" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" x={0} y={0} 
        viewBox="-0.5 -0.5 16 16" 
        xmlSpace="preserve" 
        enableBackground="new 0 0 32 32" 
        height={height} width={width} >
          <desc>{"Floorplan Streamline Icon: https://streamlinehq.com"}</desc>
          <path id="_x3C_Transparent_Rectangle_x3E__589_" d="M0 0h15v15H0Z" fill={fillColor} strokeWidth={1} />
          <path d="M13.125 0.9375H1.875c-0.515625 0 -0.9375 0.421875 -0.9375 0.9375v11.25c0 0.515625 0.421875 0.9375 0.9375 0.9375h7.03125v-0.9375c0 -1.3125 1.03125 -2.34375 2.34375 -2.34375v-0.9375c-1.828125 0 -3.28125 1.453125 -3.28125 3.28125h-1.40625v-1.875h-0.9375v1.875H1.875V1.875h3.75v6.5625h0.9375v-2.34375h1.875v-0.9375h-1.875V1.875h6.5625v3.28125h-1.875v0.9375h1.875v7.03125h-1.875v0.9375h1.875c0.515625 0 0.9375 -0.421875 0.9375 -0.9375V1.875c0 -0.515625 -0.421875 -0.9375 -0.9375 -0.9375z" strokeWidth={1} fill={"#fff"} />

      </svg>
  );
};
