import { useState, useCallback } from "react";
import { CloudUploadIcon, CrossIcon } from "../../../icons";
import UploadBiblografiaModal from "../../Common/UploadPdfModal/UploadPdfModal";
import { uploadFile } from "../../../api";
import { toast } from "react-toastify";
import PdfImg from "../../../images/pdf_icon.svg";
import axios from "axios";
import config from "../../../config";
import SpinnerLoading from "../../SpinnerLoading/SpinnerLoading";
import "./UploadBiblografia.scss";

const UploadBiblografia = ({ idPdfBibliografia = [], disabled, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleUpload = useCallback(
    async (ev) => {
      const files = ev.target.files;
      closeModal();
      setIsFileUploading(true);

      if (files.length) {
        console.log(files);
        Array.from(files).forEach(async (file) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async function () {
            try {
              const uploadedFileId = await uploadFile(file);

              onChange([
                ...idPdfBibliografia,
                { id: uploadedFileId, name: file.name },
              ]);
            } catch (error) {
              console.error("Error uploading file.", error);
              toast.error("Error uploading file.");
            } finally {
              setIsFileUploading(false);
            }
          };

          if (!file.name.endsWith(".pdf")) {
            console.error("Unsupported file format.");
            toast.error("Unsupported file format.");
            setIsFileUploading(false);
          }
        });
      }
    },
    [idPdfBibliografia, onChange, closeModal]
  );

  const handleBrowsePdf = useCallback(async (id) => {
    setIsFileUploading(true);
    try {
      const res = await axios.get(
        `${config.archeoBaseUrl}/api/private/v3/file/download/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (err) {
      toast.error("Error downloading file.");
    } finally {
      setIsFileUploading(false);
    }
  }, []);

  const handleRemovePdf = useCallback(
    (fileId) => {
      onChange(idPdfBibliografia.filter((pdf) => pdf.id !== fileId));
    },
    [idPdfBibliografia, onChange]
  );

  return (
    <div className="form-block">
      <div className="form-block-header">
        <p className="form-block-header-title">PDF Upload</p>
        <button
          className="upload-biblografia-btn"
          disabled={disabled}
          onClick={openModal}
        >
          <CloudUploadIcon />
          <span>Carica PDF</span>
        </button>
      </div>
      <div>
        {isFileUploading ? (
          <SpinnerLoading isLoading={isFileUploading} />
        ) : null}
        <div className="preveiw-container">
          {idPdfBibliografia?.length
            ? idPdfBibliografia.map((pdf) => (
                <div key={pdf?.id} className="biblografia-pdf-container">
                  <button onClick={() => handleBrowsePdf(pdf?.id)}>
                    <img src={PdfImg} alt="pdf" />
                    <p>{pdf?.name}</p>
                  </button>
                  <span
                    className={`pdf-close-icon ${disabled ? "disabled" : ""}`}
                    onClick={() => handleRemovePdf(pdf?.id)}
                  >
                    <CrossIcon />
                  </span>
                </div>
              ))
            : null}
        </div>
      </div>
      <UploadBiblografiaModal
        title={"Upload PDF"}
        showModal={showModal}
        handleUpload={handleUpload}
        closeModal={closeModal}
      />
    </div>
  );
};

export default UploadBiblografia;
