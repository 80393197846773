import React from "react";
import { AddIcon } from "../../../../icons";
import CustomTooltip from "../../../CustomTooltip";
import "./BibliografiaHeader.scss";

const BibliografiaHeader = ({ edit = false, onOpenModal }) => {
  return (
    <div className="biblografia-section-header">
      <p className="section-title">BIBLIOGRAFIA</p>
      {edit && (
        <CustomTooltip text="Aggiungi bibliografia">
          <button className="repertori-button" onClick={onOpenModal}>
            <AddIcon fillColor="#e15615" />
          </button>
        </CustomTooltip>
      )}
    </div>
  );
};

export default BibliografiaHeader;
