import React, { useState, useEffect } from "react";
import "./FeatureTable.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import { allAcronyms } from "../../../constants/acronyms";
import { getCassette, getReperti } from "../../../api";
import CustomTooltip from "../../CustomTooltip";
import { useAppContext } from "../../Context/AppContext";
import { ArchiveIcon } from "../../../icons";

export const FeatureTable = ({ properties }) => {
  const navigate = useNavigate();

  const { layers, setCassetteFilter, setRepertiFilter } = useAppContext();
  const [numberOfCassette, setNumberOfCassette] = useState(0);
  const [numberOfReperti, setNumberOfReperti] = useState(0);

  const layerKey = properties && properties.layerName ? layers.find((layer) => layer.tipo === properties.layerName).chiave : null;


  const cassetteFilter = layerKey && [
    { key: "raccolta.provenienza.tipo", value: properties.layerName },
    { key: "raccolta.provenienza.chiave", value: layerKey },
    { key: "raccolta.provenienza.valore", value: properties[layerKey] },
  ]

  const repertiFilter = layerKey && [
    { key: "provenienza.tipo", value: properties.layerName },
    { key: "provenienza.chiave", value: layerKey },
    { key: "provenienza.valore", value: properties[layerKey] },
  ]

  console.log("===| !! properties", properties);
  console.log("===| !! layers", layers);
  console.log("===| !! layerKey", layerKey);
  console.log("===| !! cassetteFilter", cassetteFilter);
  console.log("===| !! repertiFilter", repertiFilter);

  const fetchNumberOfCassette = async () => {

    const cassette = await getCassette([
      {key: "page", value: 1}, 
      { key: "pageSize", value: 1 },
      ...cassetteFilter
    ]);
    if(cassette && cassette.totalElements) setNumberOfCassette(cassette.totalElements);
  }

  const fetchNumberOfReperti = async () => {
    const reperti= await getReperti([
      {key: "page", value: 1}, 
      { key: "pageSize", value: 1 },
      ...repertiFilter
    ]);
    if(reperti && reperti.totalElements) setNumberOfReperti(reperti.totalElements);
  }
    

  
  useEffect(() => {
    if(layerKey) {
      fetchNumberOfCassette();
      fetchNumberOfReperti();
      }
  }, [properties]);


  const jumpToCassette =  () => {
    setCassetteFilter(cassetteFilter);
    navigate("/cassette");
  }

  const jumpToReperti =  () => {
    setRepertiFilter(repertiFilter);
     navigate("/reperti");
  }



  // Filter properties based on definitions
  const filteredProperties = {};
  if(properties !== null) Object.keys(properties).forEach((key) => {
    if(allAcronyms[key]){
      filteredProperties[key] = properties[key];
    }
  });

  return (
    <>
      <div className="feature-table-container">
        <table className="feature-table">
          <thead className="feature-table-header">
            <tr>
              <th className="feature-table-header-column">ACRONIMO</th>
              <th className="feature-table-header-description">DEFINIZIONE</th>
              <th className="feature-table-header-column">DATO</th>
            </tr>
          </thead>
          <tbody className="feature-table-body-container">
            {Object.entries(filteredProperties).map(([key, value]) => (
              <tr className="feature-table-data" key={key}>
                <td className="feature-table-body-column">{key}</td>
                <td className="feature-table-body-description">
                  {allAcronyms[key]}
                </td>
                <td className="feature-table-body-column">
                  {value === null ? " " : value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="db-reference">
          <p>Numero di cassette collegati: <b>{numberOfCassette}</b> </p>
          <CustomTooltip text="Vai alle cassette">
            {numberOfCassette && numberOfCassette > 0 
            ? (<button className="jump-button" onClick={() => jumpToCassette()}><ArchiveIcon fillColor="#e15615"/></button>)
            : (<ArchiveIcon filColor="#ccc"/>)}
          </CustomTooltip>
      </div>
      <div className="db-reference">
        <p>Numero di reperti collegati: <b>{numberOfReperti}</b> </p>
        <CustomTooltip text="Vai ai reperti">
          {numberOfReperti && numberOfReperti > 0 
          ? (<button className="jump-button" onClick={() => jumpToReperti()}><ArchiveIcon fillColor="#e15615"/></button>)
          : (<ArchiveIcon filColor="#ccc"/>)}
        </CustomTooltip>
      </div>
    </>
  );
};

export default FeatureTable;
