import { useState, useCallback } from "react";
import CustomModal from "../../Common/CustomModal";
import Select from "react-select";
import chroma from "chroma-js";
import { saveUser, setUserGroups, setUserRoles } from "../../../api";
import "./AddUserModal.scss";
import { toast } from "react-toastify";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

const AddUserModal = ({ isOpen, rolesData, groupsData, onClose, onSave }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const handleRoleSelectChange = useCallback((selectedRole) => {
    setSelectedRoles(selectedRole);
    console.log("selected--role--<>>>", selectedRole);
  }, []);

  const handleGroupSelectChange = useCallback((selectedGroup) => {
    setSelectedGroups(selectedGroup);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        await saveUser(formData);
        const { username } = formData;
        await setUserRoles({
          username,
          roles: selectedRoles?.map((role) => role.id),
        });
        await setUserGroups({
          username,
          groups: selectedGroups?.map((group) => group.value),
        });
        toast.success("User saved successfully");
      } catch (err) {
        console.error("Error while saving user");
        toast.error("Error while saving user");
      } finally {
        onSave();
      }
    },
    [formData, selectedRoles, selectedGroups, onSave]
  );

  return (
    <CustomModal title={"Add New User"} isVisible={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className="add-user-modal-body">
          <div className="input-wrapper">
            <label htmlFor="username" className="input-label">
              Username:
            </label>
            <input
              id="username"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="password" className="input-label">
              Password:
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="roles" className="input-label">
              Roles:
            </label>
            <Select
              name="roles"
              isMulti
              options={rolesData}
              placeholder="Select a role."
              onChange={handleRoleSelectChange}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="groups" className="input-label">
              Groups:
            </label>
            <Select
              isMulti
              name="groups"
              options={groupsData}
              placeholder="Select a group."
              styles={colourStyles}
              onChange={handleGroupSelectChange}
            />
          </div>
        </div>

        <footer className="modal-footer">
          <button type="submit" className="modal-footer-button">
            Submit
          </button>
        </footer>
      </form>
    </CustomModal>
  );
};

export default AddUserModal;
