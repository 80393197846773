export const AddIcon = ({ fillColor = "#E15615", width = 28, height = 28 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0007 2.33337C7.57232 2.33337 2.33398 7.57171 2.33398 14C2.33398 20.4284 7.57232 25.6667 14.0007 25.6667C20.429 25.6667 25.6673 20.4284 25.6673 14C25.6673 7.57171 20.429 2.33337 14.0007 2.33337ZM18.6673 14.875H14.8757V18.6667C14.8757 19.145 14.479 19.5417 14.0007 19.5417C13.5223 19.5417 13.1257 19.145 13.1257 18.6667V14.875H9.33398C8.85565 14.875 8.45898 14.4784 8.45898 14C8.45898 13.5217 8.85565 13.125 9.33398 13.125H13.1257V9.33337C13.1257 8.85504 13.5223 8.45837 14.0007 8.45837C14.479 8.45837 14.8757 8.85504 14.8757 9.33337V13.125H18.6673C19.1457 13.125 19.5423 13.5217 19.5423 14C19.5423 14.4784 19.1457 14.875 18.6673 14.875Z"
      fill={fillColor}
    />
  </svg>
);
