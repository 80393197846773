export const TrashIcon = ({
  fillColor = "#EE4B2B",
  width = 28,
  height = 28,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5819 6.10171C22.7036 5.91504 20.8253 5.77504 18.9353 5.67004V5.65837L18.6786 4.14171C18.5036 3.06837 18.2469 1.45837 15.5169 1.45837H12.4603C9.74193 1.45837 9.48527 2.99837 9.2986 4.13004L9.0536 5.62337C7.9686 5.69337 6.8836 5.76337 5.7986 5.86837L3.4186 6.10171C2.9286 6.14837 2.5786 6.58004 2.62527 7.05837C2.67193 7.53671 3.09193 7.88671 3.58193 7.84004L5.96193 7.60671C12.0753 7.00004 18.2353 7.23337 24.4186 7.85171C24.4536 7.85171 24.4769 7.85171 24.5119 7.85171C24.9553 7.85171 25.3403 7.51337 25.3869 7.05837C25.4219 6.58004 25.0719 6.14837 24.5819 6.10171Z"
      fill={fillColor}
    />
    <path
      d="M22.435 9.49663C22.155 9.20496 21.77 9.04163 21.3734 9.04163H6.6267C6.23003 9.04163 5.83337 9.20496 5.56503 9.49663C5.2967 9.78829 5.14503 10.185 5.16837 10.5933L5.8917 22.5633C6.02003 24.3366 6.18337 26.5533 10.255 26.5533H17.745C21.8167 26.5533 21.98 24.3483 22.1084 22.5633L22.8317 10.605C22.855 10.185 22.7034 9.78829 22.435 9.49663ZM15.9367 20.7083H12.0517C11.5734 20.7083 11.1767 20.3116 11.1767 19.8333C11.1767 19.355 11.5734 18.9583 12.0517 18.9583H15.9367C16.415 18.9583 16.8117 19.355 16.8117 19.8333C16.8117 20.3116 16.415 20.7083 15.9367 20.7083ZM16.9167 16.0416H11.0834C10.605 16.0416 10.2084 15.645 10.2084 15.1666C10.2084 14.6883 10.605 14.2916 11.0834 14.2916H16.9167C17.395 14.2916 17.7917 14.6883 17.7917 15.1666C17.7917 15.645 17.395 16.0416 16.9167 16.0416Z"
      fill={fillColor}
    />
  </svg>
);
